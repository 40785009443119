<template>
  <div>
    <businessHead :subHeadNum="8"/>
    <section class="subMain">
      <h3 class="bottomLineTit">HMC PAYMENT</h3>
      <p>
        간병비와 병원비 결제를 한 번에 할 수 있도록 온/오프라인 통합 결제 시스템 및 종합 정보 보호
        솔루션 페이먼트를 구축합니다.
      </p>
      <div class="subMain__img pay clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>
      <div class="subMain__ban pay">
        <div class="basicWrap clear">
          <div class="subMain__ban--left">
            <h3 class="bottomLineTit left">
              HMC PAYMENT<br />
              통합 결제 시스템
            </h3>
          </div>
          <div class="subMain__ban--rgtInfo">
            <h4>간편하게 한 번에 결제하자!</h4>
            <p>
              간병비와 병원비는 한 번에 결제가 불가능했습니다.<br />
              통합되지 않은 결제 시스템에서 오는 복잡함과 불편함.<br />
              이제 HMC PAYMENT 통합 결제 시스템을 만나보세요.
            </p>
          </div>
        </div>
      </div>
      <article class="sub__customer clear">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">주요 고객사</h3>
          <div class="sub__customer--img">
            <ul class="clear">
              <li v-for="payItem in payList" :key="payItem.num">
                <img :src="payItem.img" :alt="payItem.name">
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import businessHead from './businessHead.vue';
import clientList from '@/assets/js/clientList';
export default {
  data() {
    return {
      payList: clientList.pay
    }
  },
  components: {
    businessHead
  }
};
</script>

<style></style>
