<template>
  <div>
    <careerHead :subHeadNum="11"/>
    <section class="subMain careers">
      <h3 class="bottomLineTit">함께 나아갈 우리의 모습</h3>
      <div class="ciSliderWrap talent">
        <swiper class="cirImgList clear" :options="swiperOption">
          <swiper-slide class="cirImgList__item">
            <figure class="careers01"></figure>
            <h4>소통</h4>
            <p>
              존중하는 마음과 열린 마음으로<br />
              다양한 사람들과 유연한 협업을 한다.
            </p>
          </swiper-slide>
          <swiper-slide class="cirImgList__item">
            <figure class="careers02"></figure>
            <h4>도전</h4>
            <p>
              실패를 두려워하지 않고<br />
              끊임없이 새로운 시도를 한다.
            </p>
          </swiper-slide>
          <swiper-slide class="cirImgList__item">
            <figure class="careers03"></figure>
            <h4>동반 성장</h4>
            <p>
              책임감과 주도성으로<br />
              회사와 함께 성장하고<br />
              팀과 함께 성과를 만들어낸다.
            </p>
          </swiper-slide>
          <swiper-slide class="cirImgList__item">
            <figure class="careers04"></figure>
            <h4>긍정적 자신감</h4>
            <p>
              ‘할 수 있는 방법’을 찾는<br />
              긍정적인 마음으로<br />
              적극적으로 행동한다.
            </p>
          </swiper-slide>
        </swiper>
        <div class="ciSliderWrap__pager clear"></div>
      </div>
      <div class="careersImgList">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">
            평범한 사람들이 모여서<br />
            비범한 일을 해낸다.
          </h3>
          <p>
            주식회사 에이치엠씨네트웍스는 전문성, 창의성, 혁신을 추구하여 최고의 서비스를<br />
            제공하기 위해 끊임없이 노력하며 투명성, 정의와 배려를 바탕으로<br />
            회사를 운영하며 사회에 기여하는 기업으로 되고자 노력합니다.<br />
          </p>
        </div>
        <slick ref="slick" class="imgSlideWrap clear" :options="slickOptions2">
          <div class="imgSlideWrap__list imgSlide01">
            <img src="./../../../src/assets/img/img_careers01.jpg" alt="">
          </div>
          <div class="imgSlideWrap__list imgSlide02">
            <img src="./../../../src/assets/img/img_careers02.jpg" alt="">
          </div>
          <div class="imgSlideWrap__list imgSlide03">
            <img src="./../../../src/assets/img/img_careers03.jpg" alt="">
          </div>
          <div class="imgSlideWrap__list imgSlide04">
            <img src="./../../../src/assets/img/img_careers04.jpg" alt="">
          </div>
        </slick>
      </div>
      <div class="careersRecruit">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">채용절차</h3>
          <div class="ciSliderWrap">
            <div class="cirImgList clear">
              <div class="cirImgList__item">
                <figure class="recruit01"></figure>
                <h4>서류 전형</h4>
              </div>
              <div class="cirImgList__item">
                <figure class="recruit02"></figure>
                <h4>1차 면접(실무)</h4>
              </div>
              <div class="cirImgList__item">
                <figure class="recruit03"></figure>
                <h4>최종결과</h4>
              </div>
            </div>
          </div>
          <div class="careers__sendMail">
            이메일 접수 :
            <a href="mailto: hr@hmcnetworks.co.kr">hr@hmcnetworks.co.kr</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import careerHead from './careerHead.vue';
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        enabled: false,
        followFinger: false,
        loopedSlides: 10,
        breakpoints: {
          1024: {
            enabled: true,
            followFinger: true,
            slidesPerView: "1",
            loop: true,
            pagination: {
              el: ".ciSliderWrap__pager",
              clickable: true,
              bulletClass: "default",
              bulletActiveClass: "active",
              renderBullet: function (index, className) {
                return '<button type="button" class="' + className + '">' + "</button>";
              },
            },
          }
        },
      },
      slickOptions2: {
        responsive: [
          {
            breakpoint: 10000,
            settings: "unslick"
          },
          {
            breakpoint: 1920,
            settings: {
              centerMode: true,
              centerPadding: "250px",
              slidesToShow: 1,
              infinite: true,
              arrows: false,
            }
          },
          {
            breakpoint: 1023,
            settings: {
              centerMode: true,
              centerPadding: '20px',
            }
          }
        ]
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    careerHead,
    Slick
  },
};
</script>