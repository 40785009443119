// const baseApi = axios.create({
// 	baseURL: process.env.VUE_APP_API_URL,
// })

import axios from 'axios';
import router from '../router'
import Constants from '../constants/constants';
const apiPrefix = process.env.VUE_APP_API_URL;

function baseApi(apiUrl) {
    return axios.create({
        baseURL: apiUrl,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

function successStatusCheck(response, resolve) {
    if (response.status == 200) {
        resolve(response);
    } else if (response.status == 401) {
        router.push('/');
    } else {
        resolve('error');
    }
}

function failStatusCheck(err, reject) {
    if (err.response && err.response.status == 401) {
        router.push('/');
    } else {
        reject(err)
    }
}

export function sendContact(data) {
    return new Promise((resolve, reject) => {
            return baseApi(apiPrefix).post(Constants.CONTACT_API, data)
                .then((response) => {
                    successStatusCheck(response, resolve)
                }).catch(err => {
                    failStatusCheck(err, reject)
                });
        }
    )
}

export function getNews(queryStr) {
    return new Promise((resolve, reject) => {
            return baseApi(apiPrefix).get(Constants.NEWS_API + queryStr)
                .then((response) => {
                    successStatusCheck(response, resolve)
                }).catch(err => {
                    failStatusCheck(err, reject)
                });
        }
    )
}

export function getNewsDetail(newsId) {
    return new Promise((resolve, reject) => {
            return baseApi(apiPrefix).get(`${Constants.NEWS_API}/${newsId}`)
                .then((response) => {
                    successStatusCheck(response, resolve)
                }).catch(err => {
                    failStatusCheck(err, reject)
                });
        }
    )
}

export default {
    sendContact, getNews, getNewsDetail
}