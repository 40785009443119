<template>
  <div>
  <article>
    <div class="subBan pr">
      <h2>PR</h2>
    </div>
  </article>
  <section class="subMain boardDet">
      <div class="boardDet__listHead clear">
        <span>현재글</span>
        <div class="boardDet__listHead--tit">
          <h3>{{curData.title}}</h3>
          <small>{{ formatDate(curData.created_at) }}</small>
        </div>
      </div>
      <div class="boardDet__listBody">
        <figure></figure>
        <div v-html="curData.content"></div>
      </div>
      <ul class="boardDet__pageList">
        <li :class="nextData == null ? 'disabled' : ''">
          <button @click="itemNext()" class="clear" :disabled="nextData == null">
            <span class="before">다음글</span>
            <span class="title" v-if="nextData == null">다음 글이 없습니다.</span>
            <span class="title" v-if="nextData !== null">{{nextData.title}}</span>
            <small v-if="nextData !== null">{{ formatDate(nextData.created_at) }}</small>
          </button>
        </li>
        <li :class="preData == null ? 'disabled' : ''" :disabled="preData == null">
          <button @click="itemPrev()" class="clear" :disabled="preData == null">
            <span class="next">이전글</span>
            <span class="title" v-if="preData == null">이전 글이 없습니다.</span>
            <span class="title" v-if="preData !== null">{{preData.title}}</span>
            <small v-if="preData !== null">{{formatDate(preData.created_at)}}</small>
          </button>
        </li>
      </ul>
      <div class="boardDet__btn clear">
        <button type="button" @click="goToList()">목록</button>
      </div>
  </section>
  </div>
</template>

<script>
import Api from '../../api/index';
import dayjs from 'dayjs';
export default {
data() {
    return {
        newsList: [], // 뉴스 리스트
        curData : [],
        preData : [],
        nextData : [],
        defaultMgs : '',
        newsId : null,
        curPage : '',
        curItem : '' // 현재 글 index,
    }
  },
  watch: {
    '$route' : function() {
        if(this.$route.query.newId !== null) {
            this.newsId = this.$route.query.newsId;
            this.getNewsDetailApi();
        }
    }
  },
  mounted() {
    sessionStorage.setItem("changed", false);
    this.curItem = sessionStorage.getItem("itemIndex");
    this.newsId = this.$route.query.newsId;
    this.getNewsDetailApi();
  },
  methods: {
  // 보도자료 상세 불러오기
  getNewsDetailApi() {
  Api.getNewsDetail(parseInt(this.newsId)).then((response) => {
      if (response.status === 200) {
      let data = response.data;
      if (data.code === 200) {
          this.curData = data.data.info;
          this.preData = data.data.pre;
          this.nextData = data.data.next;
          this.rePage = this.$route.query.startPage;
          sessionStorage.setItem("newsPage", this.$route.query.page);
      } else {
          // let msg = Utils.isEmpty(data.message) ? this.defaultMsg : data.message;
          alert(this.defaultMgs);
      }} else {
          alert(this.defaultMsg);
      }
  }).catch(err => {
      console.log(err);
      alert(this.defaultMsg);
  })
  },
  // 날짜 변경
  formatDate(dateString) {
      const date = dayjs(dateString);
          // Then specify how you want your dates to be formatted
      return date.format('YYYY-MM-DD');
  },
  // 뒤로가기
  goToList() {
    this.$router.push({
      name: 'news',
      query: {
        page : this.$route.query.page,
      }
    });
    window.scrollTo(0, 0);
  },
  // 다음, 이전 버튼
  itemNext() {
    if(0 < this.curItem) {
      if(this.curItem == sessionStorage.getItem("itemIndex")) {
        sessionStorage.setItem("itemIndex", parseInt(this.curItem) - 1);
        this.curItem = this.curItem - 1;
      }
      this.$router.push({
        name: 'newsDet',
        query: {
          newsId: this.nextData.id,
          page: this.$route.query.page
        }
      });
    } else {
      sessionStorage.setItem("itemIndex", 9);
      this.curItem = 9;
      this.$router.push({
        name: 'newsDet',
        query: {
          newsId: this.nextData.id,
          page: parseInt(this.$route.query.page - 1)
        }
      });
    } 
    
  },
  itemPrev() {
    if(this.curItem < 9) {
      if(this.curItem == sessionStorage.getItem("itemIndex")) {
        sessionStorage.setItem("itemIndex", parseInt(this.curItem) + parseInt(1));
        this.curItem = this.curItem + 1;
      }
      this.$router.push({
        name: 'newsDet',
        query: {
          newsId: this.preData.id,
          page: this.$route.query.page
        }
      });
    } else {
      sessionStorage.setItem("itemIndex", 0);
      this.curItem = 0;
      this.$router.push({
        name: 'newsDet',
        query: {
          newsId: this.preData.id,
          page: parseInt(this.$route.query.page) + parseInt(1)
        }
      });
    } 
    
  },
}   
}
</script>