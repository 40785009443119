<template>
  <div>
    <article>
      <div class="subBan pr">
        <h2>PR</h2>
      </div>
    </article>
    <section class="subMain">
      <h3 class="bottomLineTit">보도자료</h3>
      <p>주식회사 에이치엠씨네트웍스의 새로운 소식과 유용한 자료들을 생생하게 알려드립니다.</p>
      <article class="boardWrap">
        <ul class="boardWrap__table">
          <li class="clear">
            <p>제목</p>
            <span>날짜</span>
          </li>
          <li
            v-for="(item, itemIndex) in newsList"
            :key="itemIndex"
            @click="indexSave(itemIndex), enterDetail(item.id)"
          >
            <router-link to="" class="clear">
              <p>
                {{ item.title }}
              </p>
              <span>{{ formatDate(item.created_at) }}</span>
            </router-link>
          </li>
        </ul>
        <div class="boardWrap__pager clear">
          <button
            type="button"
            class="prev"
            @click="pagePrev()"
            v-if="this.curPage > this.pageSize"
          >
            이전
          </button>
          <button
            type="button"
            v-for="pageItem in pageNum"
            :key="pageItem"
            @click="pageChange(pageItem)"
            :class="$route.query.page == pageItem ? 'active' : ''"
          >
            {{ pageItem }}
          </button>
          <button
            type="button"
            class="next"
            @click="pageNext()"
            v-if="this.realendPage < this.totalPage"
          >
            다음
          </button>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import Api from "../../api/index";
import * as Utils from "../../constants/utils";
import dayjs from "dayjs";

export default {
  data() {
    return {
      // 리스트
      newsList: [], // 뉴스 리스트
      totalCount: 0, // 리스트 전체 데이터 수
      // 페이지네이션
      pageSize: Utils.osType() === "mobile" ? 5 : 10, //## 몇 페이지씩 보여줄 것인지
      totalPage: 0, // 전체 페이지 수
      curPage: 1, // 현재 페이지
      pageTab: 0, // 현재 페이지들 위치
      numBox: [], // 페이지네이션에 들어갈 숫자 넣어놓는 임시 박스
      pageNum: [], // 페이지네이션에 들어갈 숫자
      startPage: 1, // 시작 페이지
      endPage: 0, // 끝 페이지
      realendPage: 0, // 페이지네이션 계산용 끝 페이지
      limit: 10,
      curItem: 0, // 현재 아이템
      //
      defaultMsg: "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.",
    };
  },
  mounted() {
    // console.log("this.$route.query.page:", this.$route.query.page);
    sessionStorage.setItem("changed", false);
    if (this.$route.query.page !== undefined) {
      this.curPage = this.$route.query.page;
    } else {
      this.curPage = sessionStorage.getItem("newsPage");
    }
    this.getNewsApi();
  },
  updated() {
    this.$router.push({
      name: "news",
      query: {
        page: this.curPage,
      },
    }).catch(() => {});
  },
  watch: {
    $route: function () {
      if (this.$route.query.page != null) {
        // 페이지 렌더링
        this.curPage = this.$route.query.page;
        this.getNewsApi();
      }
    },
  },
  methods: {
    // 뉴스 받아오기
    getNewsApi() {
      const queryStr = `?page=${this.curPage}&limit=${this.limit}`;

      Api.getNews(queryStr)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            this.newsList = data.data.list; // 뉴스 리스트 가져오기
            this.totalCount = data.data.total; // 리스트 데이터 수 가져오기
            if (data.data.list !== null) {
              // 가져온 데이터가 null이 아닐 경우
              let pageTab = this.curPage / this.pageSize;
              if (pageTab.toString().includes(".")) {
                pageTab = Math.floor(pageTab);
              } else {
                pageTab = pageTab - 1;
              }
              this.pageTab = pageTab;
              this.renderNewsList();
            }
          } else {
            console.log(this.defaultMsg);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(this.defaultMsg);
        });
    },
    // 뉴스 생성
    renderNewsList() {
      if (this.totalCount !== null) {
        // 리스트 데이터 수가 null이 아닐 경우
        this.totalPage = Math.ceil(this.totalCount / 10); // 리스트 전체 데이터 수 나누기 한 페이지에 보일 페이지 (올림)
        this.renderPagination();
      }
    },
    // 페이지네이션
    renderPagination() {
      if (this.numBox == "") {
        this.startPage = this.pageTab * this.pageSize + 1;
        this.endPage = this.pageTab * this.pageSize + this.pageSize;
        this.realendPage = this.endPage > this.totalPage ? this.totalPage : this.endPage;
        this.makePage();
      }
    },
    pageNext() {
      const nextPageFirst = this.realendPage + 1;
      this.$router.push({
        name: "news",
        query: {
          page: nextPageFirst,
        },
      });
      this.startPage = this.startPage + this.pageSize;
      this.endPage = this.endPage + this.pageSize;
      if (this.realendPage + this.pageSize < this.totalPage) {
        this.realendPage = this.realendPage + 5;
      } else {
        this.realendPage = this.totalPage;
      }
      this.makePage();
    },
    pagePrev() {
      const prevPageFirst = this.startPage - 1;
      this.$router.push({
        name: "news",
        query: {
          page: prevPageFirst,
        },
      });
      this.startPage = this.startPage - this.pageSize;
      this.endPage = this.endPage - this.pageSize;
      if (this.realendPage - this.pageSize > this.pageSize) {
        this.realendPage = this.realendPage - 5;
      } else {
        this.realendPage = this.pageSize;
      }
      this.makePage();
    },
    makePage() {
      this.numBox = [];
      for (let i = this.startPage; i < this.realendPage; i++) {
        this.numBox.push(i);
      }
      this.numBox.push(this.realendPage);
      this.pageNum = this.numBox;
    },
    pageChange(picked) {
      sessionStorage.setItem("changed", true);
      if (picked !== this.$route.query.page) {
        this.$router.push({
          name: "news",
          query: {
            page: picked,
          },
        });
        this.curPage = picked;
      } else {
        // console.log('same page');
      }
    },
    indexSave(itemIndex) {
      sessionStorage.setItem("itemIndex", itemIndex);
    },
    enterDetail(newsId) {
      this.$router.push({
        name: "newsDet",
        query: {
          newsId: newsId,
          page: this.curPage,
        },
      });
    },
    // 날짜 변경
    formatDate(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format("YYYY-MM-DD");
    },
  },
};
</script>

<style></style>
