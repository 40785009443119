import Vue from "vue";
import VueRouter from "vue-router";
import homeMain from "@/components/homeMain/homeMain";
import companyIntro from "@/components/aboutUs/companyIntro";
import companyHistory from "@/components/aboutUs/companyHistory";
import companyCi from "@/components/aboutUs/companyCi";
import hmcFnb from "@/components/business/hmcFnb";
import hmcLab from "@/components/business/hmcLab";
import hmcMedical from "@/components/business/hmcMedical";
import hmcPayment from "@/components/business/hmcPayment";
import offlineCare from "@/components/business/offlineCare";
import subSidiaries from "@/components/business/subSidiaries";
import hmcNews from "@/components/publicRelations/hmcNews";
import hmcNewsDet from "@/components/publicRelations/hmcNewsDet";
import hmcBenefits from "@/components/careerInfo/hmcBenefits";
import hmcPeople from "@/components/careerInfo/hmcPeople";
import contactApply from "@/components/contactUs/contactApply";
import notFound from "@/components/notFound.vue";

Vue.use(VueRouter);

const route = [
  // 메인 페이지
  { path: "/", component: homeMain },
  { path: "/main", component: homeMain },

  // About Us
  { path: "/company", component: companyIntro },
  { path: "/company/intro", component: companyIntro },
  { path: "/company/history", component: companyHistory },
  { path: "/company/ci", component: companyCi },

  // business
  { path: "/business", component: hmcLab },
  { path: "/business/fnb", component: hmcFnb },
  { path: "/business/lab", component: hmcLab },
  { path: "/business/medical", component: hmcMedical },
  { path: "/business/payment", component: hmcPayment },
  { path: "/business/offline", component: offlineCare },
  { path: "/business/subsidiaries", component: subSidiaries },

  // PR
  { path: "/pr", name: "news", component: hmcNews },
  { path: "/pr/news", component: hmcNews },
  { path: "/pr/news/detail", name: "newsDet", component: hmcNewsDet },

  //Careers
  { path: "/careers", component: hmcPeople },
  { path: "/careers/benefits", component: hmcBenefits },
  { path: "/careers/people", component: hmcPeople },

  // contact
  { path: "/contact", component: contactApply },
  { path: "/contact/apply", component: contactApply },

  // 에러페이지
  { path: "*", component: notFound },
];

// Vue 라우터 인스턴스 생성
const router = new VueRouter({
  mode: "history",
  routes: route,
  // 라우터 이동 시 페이지 최상단
  scrollBehavior: function (to, _from, savedPosition) {
    var newsPagination = sessionStorage.getItem("changed");
    if (savedPosition) {
      return savedPosition;
    } else if (JSON.parse(newsPagination) == true) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  },
});

export default router;
