<template>
  <div>
    <commonHeader />
    <main>
      <router-view />
    </main>
    <commonFooter />
  </div>
</template>

<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js"></script>
<script>
import commonHeader from "@/components/commonLayout/commonHeader.vue";
import commonFooter from "@/components/commonLayout/commonFooter.vue";
export default {
  name: "App",
  components: {
    commonHeader: commonHeader,
    commonFooter: commonFooter,
  },
  updated() {
    if(this.$route.name !== 'news') {
      sessionStorage.setItem("changed", false)
    }
  }
};
</script>
