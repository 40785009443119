<template>
  <header class="clear" :class="{ open: headerOver }" @mouseleave="headerOver = false">
    <div class="basicWrap">
      <div class="headerWrap">
        <h1 @click="isActiveOn(0), subMenuOn(0)">
          <router-link to="/main" class="headerWrap__menu">
            <img src="@/assets/img/ic_hmclogo_01.png" alt="HMC NETWORKS" @click="mobOpen = false" />
          </router-link>
        </h1>
        <button type="button" class="mobileBtn" @click="mobOpen = !mobOpen">전체 메뉴</button>
      </div>
      <nav :class="{ openM: mobOpen }">
        <ul class="headerWrap" @mouseover="headerOver = true">
          <li @click="isActiveOn(1)">
            <router-link
              to="/company"
              class="headerWrap__menu pc"
              :class="{ active: this.isActive == 1, ovActive: this.overActive[1] }"
              >ABOUT US</router-link
            >
            <a class="headerWrap__menu mobile" :class="{ view: this.isActive == 1 }">ABOUT US</a>
            <ul
              class="headerWrap__menu--depth2"
              @mouseover="overActiveOn(1)"
              @mouseleave="overActiveOff"
            >
              <li @click="mobDepthOff(), subMenuOn(1)">
                <router-link to="/company/intro" :class="{ activeM: subMenu == 1 }"
                  >회사 소개</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(2)">
                <router-link to="/company/history" :class="{ activeM: subMenu == 2 }"
                  >연혁</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(3)">
                <router-link to="/company/ci" :class="{ activeM: subMenu == 3 }">CI</router-link>
              </li>
            </ul>
          </li>
          <li @click="isActiveOn(2)">
            <router-link
              to="/business"
              class="headerWrap__menu pc"
              :class="{ active: this.isActive == 2, ovActive: this.overActive[2] }"
              >BUSINESS</router-link
            >
            <a class="headerWrap__menu mobile" :class="{ view: this.isActive == 2 }">BUSINESS</a>
            <ul
              class="headerWrap__menu--depth2"
              @mouseover="overActiveOn(2)"
              @mouseleave="overActiveOff"
            >
              <li @click="mobDepthOff(), subMenuOn(4)">
                <router-link to="/business/lab" :class="{ activeM: subMenu == 4 }"
                  >HMC LAB.</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(5)">
                <router-link to="/business/offline" :class="{ activeM: subMenu == 5 }"
                  >그린실버 <span>(오프라인 간병)</span></router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(6)">
                <router-link to="/business/fnb" :class="{ activeM: subMenu == 6 }"
                  >HMC F&B</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(7)">
                <router-link to="/business/medical" :class="{ activeM: subMenu == 7 }"
                  >HMC MEDICAL</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(8)">
                <router-link to="/business/payment" :class="{ activeM: subMenu == 8 }"
                  >HMC PAYMENT</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(9)">
                <router-link to="/business/subSidiaries" :class="{ activeM: subMenu == 9 }"
                  >SUBSIDIARIES</router-link
                >
              </li>
            </ul>
          </li>
          <li @click="isActiveOn(3)">
            <router-link
              :to="{ name: 'news', query: { page: 1 } }"
              class="headerWrap__menu pc"
              :class="{ active: this.isActive == 3, ovActive: this.overActive[3] }"
              >PR</router-link
            >
            <a class="headerWrap__menu mobile" :class="{ view: this.isActive == 3 }">PR</a>
            <ul
              class="headerWrap__menu--depth2"
              @mouseover="overActiveOn(3)"
              @mouseleave="overActiveOff"
            >
              <li @click="mobDepthOff(), subMenuOn(10)">
                <router-link
                  :to="{ name: 'news', query: { page: 1 } }"
                  :class="{ activeM: subMenu == 10 }"
                  >보도자료</router-link
                >
              </li>
              <li>
                <a href="https://datalab.carenation.kr/care-info" class="linkIc" target="_blank"
                  >간병동향리포트</a
                >
              </li>
            </ul>
          </li>
          <li @click="isActiveOn(4)">
            <router-link
              to="/careers"
              class="headerWrap__menu pc"
              :class="{ active: this.isActive == 4, ovActive: this.overActive[4] }"
              >CAREERS</router-link
            >
            <a class="headerWrap__menu mobile" :class="{ view: this.isActive == 4 }">CAREERS</a>
            <ul
              class="headerWrap__menu--depth2"
              @mouseover="overActiveOn(4)"
              @mouseleave="overActiveOff"
            >
              <li @click="mobDepthOff(), subMenuOn(11)">
                <router-link to="/careers/people" :class="{ activeM: subMenu == 11 }"
                  >HMC의 인재상</router-link
                >
              </li>
              <li @click="mobDepthOff(), subMenuOn(12)">
                <router-link to="/careers/benefits" :class="{ activeM: subMenu == 12 }"
                  >복리후생</router-link
                >
              </li>
            </ul>
          </li>
          <li @click="isActiveOn(5), subMenuOn(13), mobDepthOff()">
            <router-link
              to="/contact"
              class="headerWrap__menu"
              :class="{ active: this.isActive == 5 }"
              >CONTACT</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import * as Utils from "../../constants/utils";
export default {
  data() {
    return {
      isActive: 0,
      mobileOn: Utils.osType() === "mobile" ? true : false,
      overActive: [false, false, false, false, false], // PC 2depth 마우스오버 시 이벤트
      headerOver: false, // PC 헤더 상세 오픈
      mobOpen: false, // 모바일 헤더
      subMenu: 0,
    };
  },
  mounted() {
    if (sessionStorage.getItem("headerPick") !== null) {
      this.isActive = sessionStorage.getItem("headerPick");
    }
    if (sessionStorage.getItem("subPick") !== null) {
      this.subMenu = sessionStorage.getItem("subPick");
    }
  },
  watch: {
    $route: function () {
      if (this.$route.path == "/" || this.$route.path == "/main") {
        this.isActiveOn(0);
      } else if (
        this.$route.path == "/company" ||
        this.$route.path == "/company/history" ||
        this.$route.path == "/company/intro" ||
        this.$route.path == "/company/ci"
      ) {
        this.isActiveOn(1);
      } else if (
        this.$route.path == "/business" ||
        this.$route.path == "/business/lab" ||
        this.$route.path == "/business/offline" ||
        this.$route.path == "/business/fnb" ||
        this.$route.path == "/business/medical" ||
        this.$route.path == "/business/payment" ||
        this.$route.path == "/business/subSidiaries"
      ) {
        this.isActiveOn(2);
      } else if (
        this.$route.path == "/pr" ||
        this.$route.path == "/pr/news" ||
        this.$route.path == "/pr/news/detail" ||
        this.$route.name == "news" ||
        this.$route.name == "newsDet"
      ) {
        this.isActiveOn(3);
      } else if (
        this.$route.path == "/careers" ||
        this.$route.path == "/careers/benefits" ||
        this.$route.path == "/careers/people"
      ) {
        this.isActiveOn(4);
      } else if (this.$route.path == "/contact" || this.$route.path == "/contact/apply") {
        this.isActiveOn(5);
      }
      this.subMenu = sessionStorage.getItem("subPick");
    },
    mobOpen() {
      if (this.mobOpen == true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    // GNB 클릭 시 active
    isActiveOn(index) {
      if (this.mobileOn == false) {
        this.isActive = this.isActive === index ? this.isActive : index;
        sessionStorage.setItem("headerPick", this.isActive);
      } else {
        this.isActive = this.isActive === index ? 0 : index;
        sessionStorage.setItem("headerPick", this.isActive);
      }
    },

    overActiveOn(index) {
      this.overActive = [];
      this.overActive[index] = true;
    },

    // GNB mouseover 시 active
    overActiveOff() {
      this.overActive = [];
    },

    // 모바일 GNB
    mobDepthOff() {
      this.mobOpen = false;
    },
    subTenOn(index) {
      if (Utils.osType() == "mobile" && index == 10) {
        // console.log('')
      } else {
        this.subMenu = index;
        sessionStorage.setItem("subPick", this.subMenu);
      }
    },
    subMenuOn(index) {
      this.subMenu = index;
      sessionStorage.setItem("subPick", this.subMenu);
    },
  },
};
</script>

<style>
.headerWrap__menu.mobile {
  display: none;
}
@media screen and (max-width: 1023px) {
  .headerWrap__menu.pc {
    display: none;
  }
  .headerWrap__menu.mobile {
    display: block;
  }
}
</style>
