export default {
  invest : [
    { 
      num : 1,
      img: require('@/assets/img/img_invest01.png'),
      name:"HB INVESTMENT"
    },
    { 
      num : 2,
      img: require('@/assets/img/img_invest02.png'),
      name:"LSK INVESTMENT"
    },
    { 
      num : 3,
      img: require('@/assets/img/img_invest03.png'),
      name:"하나벤처스"
    },
    { 
      num : 4,
      img: require('@/assets/img/img_invest04.png'),
      name:"삼성화재"
    },
    { 
      num : 5,
      img: require('@/assets/img/img_invest05.png'),
      name:"삼성벤처투자"
    },
    { 
      num : 6,
      img: require('@/assets/img/img_invest06.png'),
      name:"신한금융투자"
    },
    { 
      num : 7,
      img: require('@/assets/img/img_invest07.png'),
      name:"KODIT 신용보증기금"
    },
    { 
      num : 8,
      img: require('@/assets/img/img_invest08.png'),
      name:"새한창업투자"
    },
    { 
      num : 9,
      img: require('@/assets/img/img_invest09.png'),
      name:"DAYLI Partners"
    }
  ],
  fnb : [
    {
      num : 1,
      img: require('@/assets/img/img_fnblogo_01.png'),
      name: '구립문성어린이집'
    },
    {
      num : 2,
      img: require('@/assets/img/img_fnblogo_02.png'),
      name: '꿈마을 미래유치원'
    },
    {
      num : 3,
      img: require('@/assets/img/img_fnblogo_03.png'),
      name: '전안시 맑은물사업소'
    },
    {
      num : 4,
      img: require('@/assets/img/img_fnblogo_04.png'),
      name: '에덴산부인과'
    },
    {
      num : 5,
      img: require('@/assets/img/img_fnblogo_05.png'),
      name: '중앙대의료원 교육 협력 현대병원'
    },
    {
      num : 6,
      img: require('@/assets/img/img_fnblogo_06.png'),
      name: '비타민 어린이집'
    },
    {
      num : 7,
      img: require('@/assets/img/img_fnblogo_07.png'),
      name: '청안성정종합사회복지관'
    },
    {
      num : 8,
      img: require('@/assets/img/img_fnblogo_08.png'),
      name: '충무사랑병원'
    },
    {
      num : 9,
      img: require('@/assets/img/img_fnblogo_09.png'),
      name: '청안중앙병원'
    },
    {
      num : 10,
      img: require('@/assets/img/img_fnblogo_10.png'),
      name: '365 웰니스의원'
    },
    {
      num : 11,
      img: require('@/assets/img/img_fnblogo_11.png'),
      name: '동산요양원 동산실버타운'
    },
    {
      num : 12,
      img: require('@/assets/img/img_fnblogo_12.png'),
      name: '성남요양병원'
    },
    {
      num : 13,
      img: require('@/assets/img/img_fnblogo_13.png'),
      name: '방가삼대 얼큰한 뼈해장국'
    },
    {
      num : 14,
      img: require('@/assets/img/img_fnblogo_14.png'),
      name: '신부정형외과의원'
    },
    {
      num : 15,
      img: require('@/assets/img/img_fnblogo_15.png'),
      name: '원주 프라임 어린이집'
    },
    {
      num : 16,
      img: require('@/assets/img/img_fnblogo_16.png'),
      name: '금진'
    },
    {
      num : 17,
      img: require('@/assets/img/img_fnblogo_17.png'),
      name: '해럴드 어린이집'
    },
    {
      num : 18,
      img: require('@/assets/img/img_fnblogo_18.png'),
      name: 'go deep'
    },
    {
      num : 19,
      img: require('@/assets/img/img_fnblogo_19.png'),
      name: '부성어린이집'
    },
    {
      num : 20,
      img: require('@/assets/img/img_fnblogo_20.png'),
      name: '송주효 요양병원'
    },
    {
      num : 21,
      img: require('@/assets/img/img_fnblogo_21.png'),
      name: '한아의료재단 문치과병원'
    },
    {
      num : 22,
      img: require('@/assets/img/img_fnblogo_22.png'),
      name: '본정형외과병원'
    },
    {
      num : 23,
      img: require('@/assets/img/img_fnblogo_23.png'),
      name: 'LH'
    },
    {
      num : 24,
      img: require('@/assets/img/img_fnblogo_24.png'),
      name: '보스톤연합치과'
    },
    {
      num : 25,
      img: require('@/assets/img/img_fnblogo_25.png'),
      name: '제중한방병원'
    },
    {
      num : 26,
      img: require('@/assets/img/img_fnblogo_26.png'),
      name: '나무아이'
    },
    {
      num : 27,
      img: require('@/assets/img/img_fnblogo_27.png'),
      name: '아이리더 유치원'
    },
    {
      num : 28,
      img: require('@/assets/img/img_fnblogo_28.png'),
      name: '제중백제한의원'
    },
    {
      num : 29,
      img: require('@/assets/img/img_fnblogo_29.png'),
      name: '토끼와 거북이 어린이집'
    },
    {
      num : 30,
      img: require('@/assets/img/img_fnblogo_30.png'),
      name: '홍영병원'
    },
    {
      num : 31,
      img: require('@/assets/img/img_fnblogo_31.png'),
      name: '푸르지오 청솔 어린이집'
    },
    {
      num : 32,
      img: require('@/assets/img/img_fnblogo_32.png'),
      name: '꿈을 키우는 놀이세상 꼬마숲'
    },
    {
      num : 33,
      img: require('@/assets/img/img_fnblogo_33.png'),
      name: '본가뼈해장국'
    },
    {
      num : 34,
      img: require('@/assets/img/img_fnblogo_34.png'),
      name: '느티나무'
    },
    {
      num : 35,
      img: require('@/assets/img/img_fnblogo_35.png'),
      name: '한성필성어린이집'
    },
  ],
  med : [
    {
      num : 1,
      img: require('@/assets/img/img_medlogo_01.png'),
      name: 'ConvaTec'
    },
    {
      num : 2,
      img: require('@/assets/img/img_medlogo_02.png'),
      name: 'Coloplast'
    },
    {
      num : 3,
      img: require('@/assets/img/img_medlogo_03.png'),
      name: 'smith&nephew'
    },
    {
      num : 4,
      img: require('@/assets/img/img_medlogo_04.png'),
      name: 'BD'
    },
    {
      num : 5,
      img: require('@/assets/img/img_medlogo_05.png'),
      name: 'B|BRAUN'
    },
    {
      num : 6,
      img: require('@/assets/img/img_medlogo_06.png'),
      name: 'HEINE'
    },
    {
      num : 7,
      img: require('@/assets/img/img_medlogo_07.png'),
      name: 'KaWe'
    },
    {
      num : 8,
      img: require('@/assets/img/img_medlogo_08.png'),
      name: '3M'
    },
    {
      num : 9,
      img: require('@/assets/img/img_medlogo_09.png'),
      name: 'HAKKO'
    },
    {
      num : 10,
      img: require('@/assets/img/img_medlogo_10.png'),
      name: '도고'
    },
    {
      num : 11,
      img: require('@/assets/img/img_medlogo_11.png'),
      name: '이화양행'
    },
    {
      num : 12,
      img: require('@/assets/img/img_medlogo_12.png'),
      name: 'LP'
    },
    {
      num : 13,
      img: require('@/assets/img/img_medlogo_13.png'),
      name: 'SPECIAL'
    },
    {
      num : 14,
      img: require('@/assets/img/img_medlogo_14.png'),
      name: 'GREETMED'
    },
    {
      num : 15,
      img: require('@/assets/img/img_medlogo_15.png'),
      name: 'GAMMEX'
    },
    {
      num : 16,
      img: require('@/assets/img/img_medlogo_16.png'),
      name: 'Spirit'
    },
    {
      num : 17,
      img: require('@/assets/img/img_medlogo_17.png'),
      name: '메디푸드'
    },
    {
      num : 18,
      img: require('@/assets/img/img_medlogo_18.png'),
      name: 'DR.KONG'
    },
    {
      num : 19,
      img: require('@/assets/img/img_medlogo_19.png'),
      name: 'TanTan'
    },
    {
      num : 20,
      img: require('@/assets/img/img_medlogo_20.png'),
      name: 'ONE MIND CARE 한마음 케어'
    },
    {
      num : 21,
      img: require('@/assets/img/img_medlogo_21.png'),
      name: '알카모 핫팩'
    },
    {
      num : 22,
      img: require('@/assets/img/img_medlogo_22.png'),
      name: 'YAMASU'
    },
    {
      num : 23,
      img: require('@/assets/img/img_medlogo_23.png'),
      name: 'N+S'
    },
    {
      num : 24,
      img: require('@/assets/img/img_medlogo_24.png'),
      name: 'EVERAID'
    },
    {
      num : 25,
      img: require('@/assets/img/img_medlogo_25.png'),
      name: '(주)메디타운'
    },
    {
      num : 26,
      img: require('@/assets/img/img_medlogo_26.png'),
      name: '(주)성심메디칼'
    },
    {
      num : 27,
      img: require('@/assets/img/img_medlogo_27.png'),
      name: '(주)녹색약품'
    },
    {
      num : 28,
      img: require('@/assets/img/img_medlogo_28.png'),
      name: 'DAYO MEDICAL'
    },
    {
      num : 29,
      img: require('@/assets/img/img_medlogo_29.png'),
      name: 'UNIX | maxstar'
    },
    {
      num : 30,
      img: require('@/assets/img/img_medlogo_30.png'),
      name: 'SPICA'
    },
    {
      num : 31,
      img: require('@/assets/img/img_medlogo_31.png'),
      name: 'SEJONG'
    },
    {
      num : 32,
      img: require('@/assets/img/img_medlogo_32.png'),
      name: '한솔의료기'
    },
    {
      num : 33,
      img: require('@/assets/img/img_medlogo_33.png'),
      name: '가나매트리스'
    },
    {
      num : 34,
      img: require('@/assets/img/img_medlogo_34.png'),
      name: 'isens'
    },
    {
      num : 35,
      img: require('@/assets/img/img_medlogo_35.png'),
      name: 'WEDENT'
    },
        {
      num : 36,
      img: require('@/assets/img/img_medlogo_36.png'),
      name: '씨피엘'
    },
    {
      num : 37,
      img: require('@/assets/img/img_medlogo_37.png'),
      name: '창림의료기(주)'
    },
    {
      num : 38,
      img: require('@/assets/img/img_medlogo_38.png'),
      name: '(주)한국백신'
    },
    {
      num : 39,
      img: require('@/assets/img/img_medlogo_39.png'),
      name: 'Feeltech'
    },
    {
      num : 40,
      img: require('@/assets/img/img_medlogo_40.png'),
      name: 'HWAJIN'
    },
    {
      num : 41,
      img: require('@/assets/img/img_medlogo_41.png'),
      name: '태창산업'
    },
    {
      num : 42,
      img: require('@/assets/img/img_medlogo_42.png'),
      name: 'TOP SYRINGE'
    },
    {
      num : 43,
      img: require('@/assets/img/img_medlogo_43.png'),
      name: '대한위재'
    },
    {
      num : 44,
      img: require('@/assets/img/img_medlogo_44.png'),
      name: '유한킴벌리'
    },
    {
      num : 45,
      img: require('@/assets/img/img_medlogo_45.png'),
      name: 'AILEE'
    },
        {
      num : 46,
      img: require('@/assets/img/img_medlogo_46.png'),
      name: '녹십자'
    },
    {
      num : 47,
      img: require('@/assets/img/img_medlogo_47.png'),
      name: 'YH MEDICAL'
    },
    {
      num : 48,
      img: require('@/assets/img/img_medlogo_48.png'),
      name: '기황산업'
    },
    {
      num : 49,
      img: require('@/assets/img/img_medlogo_49.png'),
      name: '현대의료산업'
    },
    {
      num : 50,
      img: require('@/assets/img/img_medlogo_50.png'),
      name: '(주)세운메디칼'
    },
    {
      num : 51,
      img: require('@/assets/img/img_medlogo_51.png'),
      name: 'MOW Medical'
    },
    {
      num : 52,
      img: require('@/assets/img/img_medlogo_52.png'),
      name: '밴드골드'
    },
    {
      num : 53,
      img: require('@/assets/img/img_medlogo_53.png'),
      name: '신신제약'
    },
    {
      num : 54,
      img: require('@/assets/img/img_medlogo_54.png'),
      name: ''
    },
    {
      num : 55,
      img: require('@/assets/img/img_medlogo_55.png'),
      name: '두원메디텍'
    },
        {
      num : 56,
      img: require('@/assets/img/img_medlogo_56.png'),
      name: '길우상사'
    },
    {
      num : 57,
      img: require('@/assets/img/img_medlogo_57.png'),
      name: '(주)천양사'
    },
    {
      num : 58,
      img: require('@/assets/img/img_medlogo_58.png'),
      name: 'HYUPSUNG'
    },
    {
      num : 59,
      img: require('@/assets/img/img_medlogo_59.png'),
      name: '남양프라스틱(주)'
    },
    {
      num : 60,
      img: require('@/assets/img/img_medlogo_60.png'),
      name: '(주)메디탑'
    },
  ],
  off : [
    {
      num : 1,
      img: require('@/assets/img/img_off_logo01.png'),
      name: '고려대학교구로병원'
    },
    {
      num : 2,
      img: require('@/assets/img/img_off_logo02.png'),
      name: '고려대학교안암병원'
    },
    {
      num : 3,
      img: require('@/assets/img/img_off_logo03.png'),
      name: '건국대학교병원'
    },
    {
      num : 4,
      img: require('@/assets/img/img_off_logo04.png'),
      name: '중앙대학교병원'
    },
    {
      num : 5,
      img: require('@/assets/img/img_off_logo05.png'),
      name: '일산복음요양병원암재활'
    },
    {
      num : 6,
      img: require('@/assets/img/img_off_logo06.png'),
      name: '라온힐요양병원'
    },
    {
      num : 7,
      img: require('@/assets/img/img_off_logo07.png'),
      name: '참우리요양병원'
    },
    {
      num : 8,
      img: require('@/assets/img/img_off_logo08.png'),
      name: '분당연세요양병원'
    },
    {
      num : 9,
      img: require('@/assets/img/img_off_logo09.png'),
      name: '사랑요양병원'
    },
    {
      num : 10,
      img: require('@/assets/img/img_off_logo10.png'),
      name: '메디하임효병원'
    },
    {
      num : 11,
      img: require('@/assets/img/img_off_logo11.png'),
      name: '베스트안재활요양병원'
    },
    {
      num : 12,
      img: require('@/assets/img/img_off_logo12.png'),
      name: '삼성우리요양병원'
    },
    {
      num : 13,
      img: require('@/assets/img/img_off_logo13.png'),
      name: '케어스힐요양병원'
    },
    {
      num : 14,
      img: require('@/assets/img/img_off_logo14.png'),
      name: '이음병원'
    },
    {
      num : 15,
      img: require('@/assets/img/img_off_logo15.png'),
      name: '의료법인 어울림의료재단 정성요양병원'
    },
    {
      num : 16,
      img: require('@/assets/img/img_off_logo16.png'),
      name: '정답병원'
    },
    {
      num : 17,
      img: require('@/assets/img/img_off_logo17.png'),
      name: '메이저 재활요양병원'
    },
    {
      num : 18,
      img: require('@/assets/img/img_off_logo18.png'),
      name: 'SEMiRAE'
    },
    {
      num : 19,
      img: require('@/assets/img/img_off_logo19.png'),
      name: '메디하임효'
    },
    {
      num : 20,
      img: require('@/assets/img/img_off_logo20.png'),
      name: '오크힐스분당요양병원'
    },
    {
      num : 21,
      img: require('@/assets/img/img_off_logo21.png'),
      name: '늘푸른요양병원'
    },
    {
      num : 22,
      img: require('@/assets/img/img_off_logo22.png'),
      name: '신주안요양병원'
    },
    {
      num : 23,
      img: require('@/assets/img/img_off_logo23.png'),
      name: '우리효사랑 요양병원'
    },
    {
      num : 24,
      img: require('@/assets/img/img_off_logo24.png'),
      name: '인천정병원'
    },
    {
      num : 25,
      img: require('@/assets/img/img_off_logo25.png'),
      name: '한국관광대학 노인전문병원'
    },
  ],
  pay : [
    {
      num : 1,
      img: require('@/assets/img/img_pay_logo01.png'),
      name: '쥬씨'
    },
    {
      num : 2,
      img: require('@/assets/img/img_pay_logo02.png'),
      name: '에그드랍'
    },
    {
      num : 3,
      img: require('@/assets/img/img_pay_logo03.png'),
      name: '폴바셋'
    },
    {
      num : 4,
      img: require('@/assets/img/img_pay_logo04.png'),
      name: '더벤티'
    },
    {
      num : 5,
      img: require('@/assets/img/img_pay_logo05.png'),
      name: '커피온리'
    },
    {
      num : 6,
      img: require('@/assets/img/img_pay_logo06.png'),
      name: '이디야 커피'
    },
    {
      num : 7,
      img: require('@/assets/img/img_pay_logo07.png'),
      name: '메가커피'
    },
    {
      num : 8,
      img: require('@/assets/img/img_pay_logo08.png'),
      name: '스쿨푸드'
    },
    {
      num : 9,
      img: require('@/assets/img/img_pay_logo09.png'),
      name: '홍대개미'
    },
    {
      num : 10,
      img: require('@/assets/img/img_pay_logo10.png'),
      name: '커피에 반하다'
    },
    {
      num : 11,
      img: require('@/assets/img/img_pay_logo11.png'),
      name: '지지고'
    },
    {
      num : 12,
      img: require('@/assets/img/img_pay_logo12.png'),
      name: '팔팔닭국수'
    },
    {
      num : 13,
      img: require('@/assets/img/img_pay_logo13.png'),
      name: '보글지글'
    },
    {
      num : 14,
      img: require('@/assets/img/img_pay_logo14.png'),
      name: '몬스터커피'
    },
    {
      num : 15,
      img: require('@/assets/img/img_pay_logo15.png'),
      name: '그리너'
    },
    {
      num : 16,
      img: require('@/assets/img/img_pay_logo16.png'),
      name: '포케올데이'
    },
    {
      num : 17,
      img: require('@/assets/img/img_pay_logo17.png'),
      name: '버거스테이지'
    },
    {
      num : 18,
      img: require('@/assets/img/img_pay_logo18.png'),
      name: '닭광정'
    },
    {
      num : 19,
      img: require('@/assets/img/img_pay_logo19.png'),
      name: '패트릭스와플'
    },
    {
      num : 20,
      img: require('@/assets/img/img_pay_logo20.png'),
      name: '범산목장'
    },
    {
      num : 21,
      img: require('@/assets/img/img_pay_logo21.png'),
      name: '이름없는 파스타'
    },
    {
      num : 22,
      img: require('@/assets/img/img_pay_logo22.png'),
      name: '동성분석'
    },
    {
      num : 23,
      img: require('@/assets/img/img_pay_logo23.png'),
      name: '삼성'
    },
    {
      num : 24,
      img: require('@/assets/img/img_pay_logo24.png'),
      name: '커피랑 도서관'
    },
    {
      num : 25,
      img: require('@/assets/img/img_pay_logo25.png'),
      name: '11번가'
    },
    {
      num : 26,
      img: require('@/assets/img/img_pay_logo26.png'),
      name: '현대백화점'
    },
    {
      num : 27,
      img: require('@/assets/img/img_pay_logo27.png'),
      name: 'LG전자'
    },
    {
      num : 28,
      img: require('@/assets/img/img_pay_logo28.png'),
      name: '파나소닉'
    },
    {
      num : 29,
      img: require('@/assets/img/img_pay_logo29.png'),
      name: '금호고속'
    },
    {
      num : 30,
      img: require('@/assets/img/img_pay_logo30.png'),
      name: '온양온천랜드'
    },
    {
      num : 31,
      img: require('@/assets/img/img_pay_logo31.png'),
      name: '아산시'
    },
    {
      num : 32,
      img: require('@/assets/img/img_pay_logo32.png'),
      name: '서울하늘공원'
    },
    {
      num : 33,
      img: require('@/assets/img/img_pay_logo33.png'),
      name: '완주시'
    },
    {
      num : 34,
      img: require('@/assets/img/img_pay_logo34.png'),
      name: '한국도로공사'
    },
    {
      num : 35,
      img: require('@/assets/img/img_pay_logo35.png'),
      name: '해리키즈카페'
    },
    {
      num : 36,
      img: require('@/assets/img/img_pay_logo36.png'),
      name: '노리툰'
    },
  ]
}