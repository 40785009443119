<template>
  <div>
    <businessHead :subHeadNum="4" />
    <section class="subMain lab">
      <h3 class="bottomLineTit">HMC LAB.</h3>
      <p>
        케어네이션은 빅데이터를 활용하여 의료산업 서비스의 혁신을 이끌고자 합니다.<br />
        가벼운 경증 환자부터 만성질환 환자 모두가 편하게 이용할 수 있는<br />
        최고의 헬스케어 서비스를 제공하기 위해 노력하겠습니다.
      </p>
      <div class="subMain__labImg">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>
      <div class="subMain__halfBan clear">
        <a target="_blank">
          <div class="leftBg"></div>
          <div class="subMain__halfBan--left">
            <h3><span>진료부터 간병, 요양까지 </span>모든 헬스케어를<br />연결하다</h3>
            <p>
              No.1 헬스케어 플랫폼 케어네이션2.0으로<br />
              우리 가족 건강 관리, 지금 시작하세요!
            </p>
            <div class="storeBtnWrap clear">
              <button type="button" class="google" onclick="window.open('https://play.google.com/store/apps/details?id=kr.carenation.protector')">Google Play</button>
              <button type="button" class="apple" onclick="window.open('https://apps.apple.com/kr/app/id1516171776')">App Store</button>
            </div>
          </div>
        </a>
        <a target="_blank">
          <div class="rightBg"></div>
          <div class="subMain__halfBan--rgt">
            <h3>
              내가 직접 찾는<br />
              간병 일자리!
            </h3>
            <p>
              원하는 날짜/지역/병원을 직접 선택!<br />
              앱 다운로드 후 내 도움이 필요한 환자를 케어해주세요!
            </p>
            <div class="storeBtnWrap clear">
              <button type="button" class="google" onclick="window.open('https://play.google.com/store/apps/details?id=kr.carenation.caregiver')">Google Play</button>
              <button type="button" class="apple" onclick="window.open('https://apps.apple.com/kr/app/id1644025883')">App Store</button>
            </div>
          </div>
        </a>
      </div>
      <div class="basicWrap">
        <strong>
          케어네이션은<br />
          라이프 사이클에 맞는 <br />
          모든 헬스케어 서비스를 상황별로 제공합니다.
        </strong>
      </div>
      <div class="labSliderWrap">
        <div class="basicWrap clear">
          <ul class="labSliderWrap__tapMenu clear"></ul>
          <div class="scrolling">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <swiper class="labSlideConWrap clear" :options="swiperOption">
            <swiper-slide
              class="labSlideConWrap__list"
              v-for="labItem in labBody"
              :key="labItem.img"
            >
              <div class="labSlideConWrap__list--left">
                <h4>{{ labItem.title }}</h4>
                <p>{{ labItem.desc }}</p>
                <span>{{ labItem.sub }}</span>
                <small>{{ labItem.small }}</small>
              </div>
              <div class="labSlideConWrap__list--right" :class="labItem.img"></div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="labSliderWrap__btn">
          <button type="button" class="left" slot="button-prev">이전</button>
          <button type="button" class="active"></button>
          <button type="button"></button>
          <button type="button" class="right" slot="button-next">다음</button>
        </div>
        <a
          href="https://www.carenation.kr/customer"
          target="_blank"
          class="arrowBtnBl"
        >
          간병인 문의 하러 가기
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import * as Utils from "../../constants/utils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import businessHead from "./businessHead.vue";
import labService from "@/assets/js/labService";
export default {
  data() {
    return {
      storeInfo: Utils.storeType(),
      labBody: labService.body,
      swiperOption: {
        slidesPerView: "1",
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: ".labSliderWrap__btn .right",
          prevEl: ".labSliderWrap__btn .left",
        },
        pagination: {
          el: ".labSliderWrap__tapMenu",
          clickable: true,
          bulletClass: "default",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return (
              '<li class="' +
              className +
              '">' +
              '<button type="button">' +
              "<span>" +
              labService.head[index].num +
              "</span>" +
              labService.head[index].title +
              "</button>" +
              "</li>"
            );
          },
        },
        breakpoints: {
          1024: {
            pagination: {
              el: ".labSliderWrap__btn",
              clickable: true,
              bulletClass: "default",
              bulletActiveClass: "active",
              renderBullet: function (index, className) {
                return '<button class="' + className + '"type="button">' + "</button>";
              },
            },
          },
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    businessHead,
  },
};
</script>

<style scoped>
.labSliderWrap__btn button {
  z-index: 99 !important;
}
.labSlideConWrap__list--left p,
.labSlideConWrap__list--left span {
  white-space: pre-line;
}
</style>
