export default {
  slide : [
    {
      title : '주식회사 에이치엠씨네트웍스',
      desc : '환자존중 이념을 바탕으로 의료산업에 선한 영향력을 행사합니다.',
      link : '/company/intro'
    },
    {
      title : 'HMC LAB.',
      desc : '환자의 의료 정보 및 이동을 기반으로 한 개인 맞춤형 연결 플랫폼으로 환자와 간병인, 의료기관을 연결하는 앱 서비스, 케어네이션을 개발합니다.',
      link : '/business/lab'
    },
    {
      title : '그린실버',
      subtitle : '(오프라인 간병)',
      desc : '다년간의 업계 경력 노하우로 60여 곳의 1,2,3차 의료기관에 간병 서비스를 안정적으로 연결하고 있습니다.',
      link : '/business/offline'
    },
    {
      title : 'HMC F&B',
      desc : '친환경 물류 인프라를 기반으로 3만 여종의 건강한 식자재를 병원 및 다수의 기관에 신선하게 공급합니다.',
      link : '/business/fnb'
    },
    {
      title : 'HMC MEDICAL',
      desc : '의료기기 및 의료, 임상, 복지 용품 등 약 3,000 여 가지의 의료소모품 유통 및 온-오프라인 판매를 진행합니다.',
      link : '/business/medical'
    },
    {
      title : 'HMC PAYMENT',
      desc : '간병비와 병원비 결제를 한 번에 할 수 있도록 온/오프라인 통합 결제 시스템 및 종합 정보 보호 솔루션 페이먼트를 구축합니다.',
      link : '/business/payment'
    },
    {
      title : 'SUBSIDIARIES',
      desc : '헬스케어 시장 전체를 연결하는 비즈니스 서클을 구축하기 위해 다양한 파트너십을 형성하며 성장하고 있습니다.',
      link : '/business/subsidiaries'
    }
  ],
  service: [
    {
      class : 'lab',
      title : 'HMC LAB.',
      desc : '환자의 의료 정보 및 이동을 기반으로 한 개인 맞춤형 연결 플랫폼으로 환자와 간병인, 의료기관을 연결하는 앱 서비스, 케어네이션을 개발합니다.',
      link : '/business/lab'
    },
    {
      class : 'off',
      title : '그린실버(오프라인 간병)',
      desc : '국내 최대 규모의 간병인협회로써 다년간의 업계 경력 노하우로 60여 곳의 1,2,3차 의료기관에 간병 서비스를 안정적으로 연결하고 있습니다.',
      link : '/business/offline'
    },
    {
      class : 'fnb',
      title : 'HMC F&B',
      desc : '친환경 물류 인프라를 기반으로 3만 여종의 건강한 식자재를 병원 및 다수의 기관에 신선하게 공급합니다.',
      link : '/business/fnb'
    },
    {
      class : 'med',
      title : 'HMC MEDICAL',
      desc : '의료기기 및 의료, 임상, 복지 용품 등 약 3,000 여 가지의 의료소모품 유통 및 온-오프라인 판매를 진행합니다.',
      link : '/business/medical'
    },
    {
      class : 'pay',
      title : 'HMC PAYMENT',
      desc : '간병비와 병원비 결제를 한 번에 할 수 있도록 온/오프라인 통합 결제 시스템 및 종합 정보 보호 솔루션 페이먼트를 구축합니다.',
      link : '/business/payment'
    },
  ]
}