<template>
  <div>
    <businessHead :subHeadNum="6"/>
    <section class="subMain bizFnb">
      <h3 class="bottomLineTit">HMC F&B</h3>
      <p>
        HMC F&B는 대기업 3사를 통한 위탁 공급망과 HMC 자체 다이렉트 공급망 병행 운영으로 고객
        경쟁력을 극대화합니다.<br />
        또한, 고객사의 운영여건 및 선호방식에 맞춰 자유롭게 식자재 납품 시스템을 선택할 수 있어
        최고의 경쟁력을 선사합니다!
      </p>
      <div class="subMain__img food clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>
      <div class="subMain__ban clear">
        <swiper :options="swiperOption">
          <!-- 슬라이드 1 -->
          <swiper-slide class="fnb01">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC F&B만의 특징</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>01</span>다이렉트 공급 시스템</h4>
                <p>
                  주식회사 에이치엠씨네트웍스만의 현지 시장조사와 물류 시스템을 기반으로<br />기존 식자재 대비
                  합리적인 가격 및 품질 경쟁력을 제공합니다.
                </p>
              </div>
              <!-- // 슬라이드 1 -->
            </div>
          </swiper-slide>
          <!-- //슬라이드 1 -->

          <!-- 슬라이드 2 -->
          <swiper-slide class="fnb02">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC F&B만의 특징</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>02</span>위탁 공급 시스템</h4>
                <p>
                  국내 식자재 공급을 대표하는 대기업 3사의 비교 견적을 통해<br />
                  보다 다양한 품목을 보다 저렴하게 공급합니다.
                </p>
                <div class="fnbImgWrap clear">
                  <img src="@/assets/img/img_fnb01.png" alt="삼성웰스토리" />
                  <img src="@/assets/img/img_fnb02.png" alt="한화호텔&리조트" />
                  <img src="@/assets/img/img_fnb03.png" alt="현대그린푸드" />
                </div>
              </div>
            </div>
          </swiper-slide>
          <!-- // 슬라이드 2 -->

          <!-- 슬라이드 3 -->
          <swiper-slide class="fnb03">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC F&B만의 특징</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>03</span>식자재 관리 전산시스템</h4>
                <p>
                  편리한 검색 및 발주는 물론, 오발주 예방을 위한 식자재 전산 시스템으로<br />
                  보다 편리하게 이용하실 수 있습니다.
                </p>
              </div>
            </div>
          </swiper-slide>
          <!-- // 슬라이드 3 -->
        </swiper>
        <!-- 공통 요소 -->
        <div class="fnbBtnWrap clear">
          <button type="button" class="prev" slot="button-prev">이전</button>
          <button type="button" class="next" slot="button-next">다음</button>
        </div>
        <article class="dotPager"></article>
        <!-- //공통 요소 -->
      </div>

      <article class="sub__customer clear">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">주요 고객사</h3>
          <div class="sub__customer--img">
            <ul class="clear">
              <li v-for="fnbItem in fnbList" :key="fnbItem.num">
                <img :src="fnbItem.img" :alt="fnbItem.name">
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import businessHead from './businessHead.vue';
import clientList from '@/assets/js/clientList';
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      fnbList : clientList.fnb,
      swiperOption: {
        slidesPerView: "1",
        loop: true,
        autoplay: {
          delay: 2000,
        },
        navigation: {
          nextEl: ".fnbBtnWrap .next",
          prevEl: ".fnbBtnWrap .prev",
        },
        pagination: {
          el: ".dotPager",
          clickable: true,
          bulletClass: "default",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return '<button type="button" class="' + className + '">' + "</button>";
          },
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    businessHead
  },
};
</script>