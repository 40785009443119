<template>
  <div>
    <careerHead :subHeadNum="12"/>
    <section class="subMain">
      <h3 class="bottomLineTit">복리후생</h3>
      <ul class="careers__infoList">
        <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
          <div class="careers__infoList--img"></div>
          <div class="careers__infoList--txt">
            <h4>음료 및 간식제공</h4>
            <p>
              든든하게 먹어야 일도 잘하죠!<br />
              HMC는 아침과 점심, 커피 및 간식을 제공합니다!<br />
              마음껏 드세요!
            </p>
          </div>
        </li>
        <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
          <div class="careers__infoList--img"></div>
          <div class="careers__infoList--txt">
            <h4>무한 도서, 의무 교육 지원</h4>
            <p>
              우리는 구성원들과 함께 성장하고 싶습니다.<br />
              구성원들의 능률을 높이기 위해서 투자를 아끼지 않습니다.<br />
              지적 성장을 위해 무한 도서 지원, 전문성 스킬 향상을 위한<br />
              의무 교육을 지원해 드리고 있습니다.
            </p>
          </div>
        </li>
        <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
          <div class="careers__infoList--img"></div>
          <div class="careers__infoList--txt">
            <h4>듀얼 모니터, 노트북 제공</h4>
            <p>
              HMC는 업무의 효율성을 위해 노트북과<br />
              듀얼 모니터를 지원해드리고 있습니다.
            </p>
          </div>
        </li>
        <li class="clear" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
          <div class="careers__infoList--img"></div>
          <div class="careers__infoList--txt">
            <h4>쾌적한 휴식공간</h4>
            <p>
              구성원들의 업무 향상을 위해 쾌적한 휴식공간을 제공합니다.<br />
              공기청정기와 게임기, 보드게임 및 안마의자가 있는<br />
              편안한 카페테리아에서 쉬세요!
            </p>
          </div>
        </li>
      </ul>
      <div class="careers__certify">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">인증현황</h3>
          <ul class="careers__certify--list clear">
            <li>
              <h4>
                기업부설연구소<br />
                인증
              </h4>
              <p>기술력 향상 기업 인증</p>
            </li>
            <li>
              <h4>
                근로(노동)시간<br />
                인증 단축 확인
              </h4>
              <p>주 52시간 이하 근무 인증</p>
            </li>
            <li>
              <h4>
                메인비즈<br />
                인증
              </h4>
              <p>경영혁신 중소기업 인증</p>
            </li>
            <li>
              <h4>
                이노비즈<br />
                인증
              </h4>
              <p>기술혁신 중소기업 인증</p>
            </li>
            <li>
              <h4>
                미래성과공유기업<br />
                확인
              </h4>
              <p>
                성과급 지급, 매년 임금수준 상승,<br />
                스톡옵션의 보장 인증
              </p>
            </li>
            <li>
              <h4>
                벤처기업<br />
                인증
              </h4>
              <p>벤처 투자 기업 인증</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import careerHead from './careerHead.vue';
export default {
  components: {
    careerHead
  }
}
</script>