<template>
  <div>
    <aboutHead :subHeadNum="3"/>
    <section class="subMain ci">
      <h3 class="bottomLineTit">CI</h3>
      <p>
        HMC는 "Healthcare Management Company" 를 의미합니다.<br />
        블루계열 색상의 타이포가 연결된 형식으로 구성된 주식회사 에이치엠씨네트웍스의 CI는 기업의 메세지를 담은
        모습을 상징합니다.
      </p>
      <small>
        * 적용매체의 특수성으로 인해 색상표현이 불가능한 경우를 제외한<br />
        모든 상황에 동일하게 표현되어야 하며, 임의로 변경 사용할 수 없습니다.
      </small>
      <div>
        <div class="ci__logo"></div>
        <div class="ci__downBtn clear">
          <a href="/HMC_NETWORKS.png" download>PNG 다운로드</a>
          <a href="/HMC_NETWORKS.ai" download>AI 다운로드</a>
        </div>
        <div class="ci__concept">
          <div class="basicWrap clear">
            <div class="ci__concept--txt">
              <h3 class="bottomLineTit left">Concept</h3>
              <p>
                'HMC' 타이포를 하나의 형태로 연결시켜 고객과 기업간의 연결, 즉 소통을 의미합니다.
                또한, 파랑 계열의 색상을 사용해 신뢰와 전문성을 상징해 점점 발전하는 HMC의
                가능성을 상징합니다.
              </p>
            </div>
            <div class="ci__concept--color">
              <div>
                <p>C 100</p>
                <p>M 100</p>
                <p>Y 26</p>
                <p>K 25</p>
              </div>
              <div>
                <p>C 87</p>
                <p>M 52</p>
                <p>Y 0</p>
                <p>K 0</p>
              </div>
            </div>
            <article class="ciSliderWrap">
              <swiper class="cirImgList clear" :options="swiperOption">
                <swiper-slide class="cirImgList__item">
                  <figure class="ci01">
                    <span></span>
                  </figure>
                  <h4>신뢰</h4>
                </swiper-slide>
                <swiper-slide class="cirImgList__item">
                  <figure class="ci02">
                    <span></span>
                  </figure>
                  <h4>전문성</h4>
                </swiper-slide>
                <swiper-slide class="cirImgList__item">
                  <figure class="ci03">
                    <span></span>
                  </figure>
                  <h4>소통</h4>
                </swiper-slide>
              </swiper>
              <div class="ciSliderWrap__pager clear" slot="pagination"></div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import aboutHead from './aboutHead.vue';
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        enabled: false,
        followFinger: false,
        breakpoints: {
          1024: {
            enabled : true,
            followFinger: true,
            slidesPerView: '1',
            loop: true,
            pagination : {
              el: ".ciSliderWrap__pager",
              clickable : true,
              bulletClass: 'default',
              bulletActiveClass: 'active',
              renderBullet: function (index, className) {
                return '<button type="button" class="' + className + '">' + '</button>';
              }
            },
          }
        }
      }
    }
  },
  components: {
    swiper,
    swiperSlide,
    aboutHead
  }
}
</script>