<template>
  <section class="error">
    <div class="basicWrap clear">
      <div class="error__tit">
        <span>ERROR 404</span>
        <h2>페이지를 찾을 수 없습니다.</h2>
        <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
        <router-link to="/">메인으로 가기</router-link>
      </div>
      <div class="error__img"></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
