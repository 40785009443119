<template>
  <article>
    <div class="subBan about">
      <h2>ABOUT US</h2>
    </div>
    <div class="subTabMenu">
      <ul class="clear">
        <li>
          <router-link to="/company/intro" :class="{'active' : subHeadNum == 1}">회사 소개</router-link>
        </li>
        <li>
          <router-link to="/company/history" :class="{'active' : subHeadNum == 2}">연혁</router-link>
        </li>
        <li>
          <router-link to="/company/ci" :class="{'active' : subHeadNum == 3}">CI</router-link>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  props : {
    subHeadNum : Number
  },
  mounted() {
    sessionStorage.setItem("subPick", this.subHeadNum);
  }
}
</script>