<template>
  <section class="main">
    <div class="mainSlideWrap">
      <slick ref="slick" :options="slickOptions" @beforeChange="resetAni" @afterChange="startAni">
        <a class="mainSlideItem" :class="'mainSlide' + 0 + (i+1)" v-for="(mainSlide, i) in mainSlideList" :key="i" :virtualIndex="i">
          <div class="basicWrap">
            <div class="mainSlideWrap__tit">
              <h2>{{mainSlide.title}}<span>{{mainSlide.subtitle}}</span></h2>
              <p v-html="mainSlide.desc"></p>
              <router-link :to="mainSlide.link" class="detailBtn">
                <span>Detail view</span>
              </router-link>
            </div>
            <div class="mainSlideWrap__pageNum">
              <p><span>0{{i+1}}</span>/ 07</p>
              <div class="basicWrap">
                <div class="mainSlideWrap__pageNum--progress">
                  <span :class="{'proAni' : proFlag}"></span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </slick>
      <div class="mainSlideWrap__pager clear" style="z-index:2" :class="{'grBtn' : greyBt}">
        <button type="button" class="left" slot="button-prev">이전</button>
        <button type="button" class="next" slot="button-next">다음</button>
      </div>
    </div>
    <div class="main__service">
      <div class="basicWrap clear">
        <div class="main__service--tit">
          <h3>Our Service</h3>
          <p>
            주식회사 에이치엠씨네트웍스는 전문성, 창의성, 혁신을 추구하여<br />최고의 서비스를 제공하기 위해 끊임없이 노력합니다.
          </p>
        </div>
        <ul class="main__service--list clear">
          <li v-for="(mainService,i) in mainServiceList" :key="i" :class="mainService.class" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <router-link :to="mainService.link">
              <figure></figure>
              <h4>{{mainService.title}}</h4>
              <p>{{mainService.desc}}</p>
              <span class="detView">Detail view</span>
            </router-link>
          </li>
          <li class="subsi" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
            <router-link to="/business/subSidiaries">
              <figure></figure>
              <h4>SUBSIDIARIES</h4>
              <p>
                헬스케어 시장 전체를 연결하는 비즈니스 서클을 구축하기 위해
                다양한 파트너십을 형성하며 성장하고 있습니다.
              </p>
              <span class="detView">Detail view</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main__news">
      <div class="basicWrap clear">
        <div class="main__news--titBox">
          <h3>HMC NEWS</h3>
          <p>
            주식회사 에이치엠씨네트웍스의 새로운 소식과<br />
            유용한 자료들을 생생하게 알려드립니다.
          </p>
          <router-link :to="{ name: 'news', query: { page: 1 } }">
            Detail view
          </router-link>
        </div>
        <ul class="main__news--list">
          <li @click="goNews(225,1)">
            <p>케어네이션, ‘가사돌봄’ 서비스 출시 … 매칭 서비스 영역 확대</p>
            <span>생활 청소의 가사 영역과 일상 돌봄의 영역을 제공하는 가사돌봄 서비스를 오픈하여 일상생활에서 필요한 영역의 도움을 받을 수 있게 한</span>
          </li>
          <li @click="goNews(217,2)">
            <p>
              “ 케어네이션” 장기요양보험사업 출사표, “ ㈜어르신세상 ” 인수
            </p>
            <span>장기요양보험서비스 전문기업 ㈜어르신세상을 100% 완전 자회사로 인수하는 계약을 체결하여 장기요양보험사업의 디지털 트랜스포메이션에 첫걸음을 내딛는다.</span>
          </li>
          <li @click="goNews(200,3)">
            <p>헬스케어 슈퍼 플랫폼 케어네이션, 배우 차승원 전속 모델 발탁</p>
            <span>모두에게 사랑받는 차승원과 헬스케어 서비스로 확장한 케어네이션이 만나 큰 시너지 효과를 낼 것으로 기대된다.</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import mainSlide from '@/assets/js/mainSlide'
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  data () {
    return {
      mainSlideList : mainSlide.slide,
      mainServiceList : mainSlide.service,
      greyBt: false,
      proFlag: true,
      curSlide: 0,
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        prevArrow: ".left",
        nextArrow: ".next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              pauseOnHover: false,
            }
          }
        ]
      }
    }
  },
  components: {
    Slick
  },
  methods: {
    resetAni() {
      this.proFlag = false
    },
    startAni(event, slick, currentSlide) {
      if(currentSlide == 4) {
        this.greyBt = true
      } else {
        this.greyBt = false
      }
      this.proFlag = true
    },
    goNews(id,page) {
      this.$router.push({ path: 'pr/news/detail', query: { newsId : id, page: page } }).catch(()=> {})
    }
  }
}
</script>