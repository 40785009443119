<template>
  <div>
    <businessHead :subHeadNum="7"/>
    <section class="subMain medical">
      <h3 class="bottomLineTit">HMC MEDICAL</h3>
      <p>
        HMC 메디컬은 의료전문 B2B 와 B2C 쇼핑몰을 운영하면서 의료용품, 의료기기, 한방용품, 복지용품
        등<br />
        약 3,000여 가지 고품질 의료소모품을 저렴한 가격으로 유통 및 온-오프라인 판매를 진행합니다.
      </p>
      <div class="subMain__img med clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>
      <div class="subMain__halfBan clear">
        <a href="http://www.에이치엠씨메디컬.com" target="_blank">
          <div class="leftBg"></div>
          <div class="subMain__halfBan--left">
            <h3>일반회원 전용 쇼핑몰</h3>
            <button type="button" class="shopBtn">홈페이지 가기</button>
          </div>
        </a>
        <a href="http://hmc0519.godo.co.kr" target="_blank">
          <div class="rightBg"></div>
          <div class="subMain__halfBan--rgt">
            <h3>사업자회원 전용 쇼핑몰</h3>
            <button type="button" class="shopBtn">홈페이지 가기</button>
          </div>
        </a>
      </div>

      <article class="sub__customer clear">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">주요 고객사</h3>
          <div class="sub__customer--img">
            <ul class="clear">
              <li v-for="medItem in medList" :key="medItem.num">
                <img :src="medItem.img" :alt="medItem.name">
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import businessHead from './businessHead.vue';
import clientList from '@/assets/js/clientList';
export default {
  data() {
    return {
      medList : clientList.med
    }
  },
  components: {
    businessHead
  }
};
</script>
