<template>
  <article>
    <div class="subBan career">
      <h2>CAREERS</h2>
    </div>
    <div class="subTabMenu">
      <ul class="clear">
        <li>
          <router-link to="/careers/people" :class="{'active' : subHeadNum == 11}">HMC의 인재상</router-link>
        </li>
        <li>
          <router-link to="/careers/benefits" :class="{'active' : subHeadNum == 12}">복리후생</router-link>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  props : {
    subHeadNum : Number
  },
  mounted() {
    sessionStorage.setItem("subPick", this.subHeadNum);
  }
}
</script>