<template>
  <div>
    <article>
      <div class="subBan contact">
        <h2>CONTACT</h2>
      </div>
    </article>
    <section class="subMain">
      <h3 class="bottomLineTit">상담 신청하기</h3>
      <form action="">
        <div class="contactInfo">
          <h4>개인정보 수집 및 이용안내</h4>
          <div class="contactInfo__notice">
            <p>주식회사 에이치엠씨네트웍스는 상담 신청서 제출자에게 아래와 같이 개인정보를 수집하고 있습니다.</p>
            <ol>
              <li>
                수집 개인정보 항목<br />
                [필수] 이름, 연락처, 이메일 주소 / [선택] 기업명
              </li>
              <li>
                개인정보의 수집 및 이용목적<br />
                상담 신청서 제출자의 본인 확인 및 문의 대응
              </li>
              <li>
                개인정보의 이용 기간<br />
                상담 신청서에 작성된 개인정보는 상담 신청서 제출자의 조회를 위하여 수집일로부터
                1년간 보관하며, 이후 해당 정보를 지체 없이 파기합니다.
              </li>
              <li>
                동의 거부권리 및 유의사항 안내<br />
                위와 같은 개인정보 수집 동의를 거부할 수 있습니다. 다만 동의를 거부하는 경우, 상담
                신청서를 제출할 수 없습니다.
              </li>
            </ol>
            <p>
              주식회사 에이치엠씨네트웍스는 [제목] , [기업명], [상담 신청 내용]에 개인정보를 입력하는 행위를
              금지하고 있습니다.<br />
              [제목] , [기업명], [상담 신청 내용]에 개인정보가 포함된 경우, 해당 문의는 삭제될 수
              있습니다.
            </p>
          </div>
          <div class="contactInfo__check clear">
            <input type="checkbox" id="chk1_1" v-model="agreeCheck"/>
            <label for="chk1_1">개인정보 수집 및 이용에 대해서 동의합니다.</label>
          </div>
          <div class="contactForm">
            <ul class="contactForm__list clear">
              <li>
                <label for="txt1_1">이름<span>필수</span></label>
                <input type="text" id="txt1_1" placeholder="이름을 입력해주세요." v-model="contactName"/>
              </li>
              <li>
                <label for="txt2_1">연락처<span>필수</span></label>
                <input type="text" id="txt2_1" placeholder="연락처를 입력해주세요." v-model="contactPhone"/>
              </li>
              <li>
                <label for="txt3_1">이메일 주소<span>필수</span></label>
                <input type="text" id="txt3_1" placeholder="이메일 주소를 입력해주세요." v-model="contactEmail"/>
              </li>
              <li>
                <label for="txt4_1">기업명</label>
                <input type="text" id="txt4_1" placeholder="기업명을 입력해주세요." v-model="contactCompany"/>
              </li>
              <li>
                <label for="txt5_1">상담 신청 내용<span>필수</span></label>
                <textarea id="txt5_1" placeholder="상담 신청 내용을 입력해주세요." v-model="contactContent"></textarea>
              </li>
            </ul>
            <button type="button" class="contactBtn" @click="checkForm()">상담 신청서 제출하기</button>
          </div>
        </div>
        <div class="contactAddrWrap" style="position: relative">
          <swiper class="contactAddrWrap" :options="swiperOption">
            <!-- 지사별 정보 -->
            <swiper-slide class="addrBan01">
              <div class="basicWrap clear">
                <h3 class="bottomLineTit left">지사 별 정보</h3>
                <div class="contactAddr__list">
                  <ul class="clear">
                    <li>
                      <h4>HMC LAB.</h4>
                      <p>
                        서울시 강남구 테헤란로 8길 37 한동빌딩 3층, 5층<br />
                        Tel. 1811-5949 / Fax. 070-7966-5949<br />
                        홈페이지 www.carenation.kr
                      </p>
                    </li>
                    <li>
                      <h4>그린실버(오프라인 간병)</h4>
                      <p>
                        *개인 간병사업부<br />
                        서울시 강남구 테헤란로8길 37 한동빌딩 5층 <br />
                        Tel. 02.572.7774 / Fax. 0303.3447.8891
                      </p>
                      <p class="mt8">
                        *공동 간병사업부<br />
                        경기도 광주시 오포읍 오포로 480<br />
                        Tel. 031.423.8891 / Fax. 0303.3447.8891
                      </p>
                    </li>
                    <li>
                      <h4>HMC F&B</h4>
                      <p>
                        충청남도 천안시 동남구 삼룡1길 50<br />
                        Tel. 041-551-5060 / Fax. 0303-3139-7410
                      </p>
                    </li>
                    <li>
                      <h4>HMC MEDICAL</h4>
                      <p>
                        경기도 광주시 오포읍 오포로 480<br />
                        Tel. 02-6925-5009 / Fax. 0303-3139-7410
                      </p>
                    </li>
                    <li>
                      <h4>HMC PAYMENT</h4>
                      <p>
                        경기도 성남시 수정구 위례광장로 19,<br />
                        아이페리온 9층 906호<br />
                        Tel. 031-423-8891 / Fax. 0303-3447-8891
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
            <!-- //지사별 정보 -->

            <swiper-slide class="addrBan02 ">
              <!-- 자회사 별 정보 PC -->
              <div class="basicWrap pc clear">
                <h3 class="bottomLineTit left">자회사 별 정보</h3>
                <div class="contactAddr__list">
                  <ul class="contactAddr__list--left">
                    <li>
                      <h4>케어네이션 인슈어런스㈜</h4>
                      <p>
                        서울시 강남구 역삼로 128, 3층 (역삼동, 양원빌딩별관)<br />
                        Tel. 070-4681-0443
                      </p>
                    </li>
                    <li>
                      <h4>㈜씨엔컴퍼니</h4>
                      <p>
                        경기도 광명시 새빛공원로 67, 자이타워 A동 2409호<br />
                        Tel. 1661-9110 / E. office@cncompany.co.kr
                      </p>
                    </li>
                    <li>
                      <h4>㈜그로우플러스</h4>
                      <p>
                        서울시 마포구 동교로 193 7층<br />
                        Tel. 010-7194-4222
                      </p>
                    </li>
                  </ul>
                  <ul class="contactAddr__list--right">
                    <li>
                      <h4>㈜어르신세상</h4>
                      <p>
                        <span>[본부]</span><br />
                        경기도 안양시 동안구 흥안대로457(평촌동) 4층<br />
                        Tel. 031-422-1090 / Fax. 070-4369-0276
                      </p>
                      <p>
                        *동안센터<br />
                        경기도 안양시 동안구 흥안대로457(평촌동) 2층<br />
                        Tel. 031-426-1090 / Fax. 031-426-1096
                      </p>
                      <p>
                        *만안센터<br />
                        경기도 안양시 만안구 안양로216<br />
                        공평빌딩 4층, 7층<br />
                        Tel. 031-464-0220 / Fax. 031-464-0221
                      </p>
                      <p>
                        *의왕센터<br />
                        경기도 의왕시 안양판교로215(청계동)<br />
                        골드프라자 3층<br />
                        Tel. 031-422-2022 / Fax. 031-422-2023
                      </p>
                      <p>
                        *요양보호사교육원<br />
                        경기도 동안구 관악대로476(관앙동) 3층<br />
                        Tel. 031-422-4111 / Fax. 031-422-1096
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //자회사 별 정보 PC -->

              <!-- 자회사 별 정보 Mobile버전 -->
              <div class="basicWrap mobile clear">
                <h3 class="bottomLineTit left">자회사 별 정보</h3>
                <ul class="contactAddr__list">
                  <li>
                    <h4>케어네이션 인슈어런스㈜</h4>
                    <p>
                      서울시 강남구 역삼로 128, 3층 (역삼동, 양원빌딩별관)<br />
                      Tel. 070-4681-0443
                    </p>
                  </li>
                  <li>
                    <h4>㈜씨엔컴퍼니</h4>
                    <p>
                      경기도 광명시 새빛공원로 67, 자이타워 A동 2409호<br />
                      Tel. 1661-9110 / E. office@cncompany.co.kr
                    </p>
                  </li>
                  <li>
                    <h4>㈜어르신세상</h4>
                    <p>
                      <span>[본부]</span><br />
                      경기도 안양시 동안구 흥안대로457(평촌동) 4층<br />
                      Tel. 031-422-1090 / Fax. 070-4369-0276
                    </p>
                    <p>
                      *동안센터<br />
                      경기도 안양시 동안구 흥안대로457(평촌동) 2층<br />
                      Tel. 031-426-1090 / Fax. 031-426-1096
                    </p>
                    <p>
                      *만안센터<br />
                      경기도 안양시 만안구 안양로216 공평빌딩 4층, 7층<br />
                      Tel. 031-464-0220 / Fax. 031-464-0221
                    </p>
                    <p>
                      *의왕센터<br />
                      경기도 의왕시 안양판교로215(청계동)<br />
                      골드프라자 3층<br />
                      Tel. 031-422-2022 / Fax. 031-422-2023
                    </p>
                    <p>
                      *요양보호사교육원<br />
                      경기도 동안구 관악대로476(관앙동) 3층<br />
                      Tel. 031-422-4111 / Fax. 031-422-1096
                    </p>
                  </li>
                  <li>
                    <h4>㈜그로우플러스</h4>
                    <p>
                      서울시 마포구 동교로 193 7층<br />
                      Tel. 010-7194-4222
                    </p>
                  </li>
                </ul>
              </div>
              <!-- //자회사 별 정보 Mobile버전 -->
            </swiper-slide>
          </swiper>
          <div class="contactAddrWrap__btn clear">
            <button type="button" class="left">이전</button>
            <button type="button" class="right">다음</button>
          </div>
          <article class="dotPager clear">
            <button type="button" class="active"></button>
            <button type="button"></button>
          </article>
        </div>
      </form>
    </section>
    <aside class="popup" :class="popup.classActive == 'active' ? 'active' : ''">
      <div class="popupWrap">
        <div class="popupWrap__tit">
          <img src="@/assets/img/ic_check_bl.png" alt="" aria-hidden v-if="popup.type == 'success'"/>
          <img src="@/assets/img/ic_fail_red.png" alt="" aria-hidden v-if="popup.type == 'fail'"/>
          <h2 v-if="this.popup.type == 'success'">접수가 완료되었습니다.</h2>
          <h2 v-if="this.popup.type == 'fail'">접수에 실패했습니다.</h2>
          <p v-if="this.popup.type == 'success'">문의해주셔서 감사합니다.</p>
          <p>{{this.popup.msg}}</p>
        </div>
        <div class="buttonWrap clear">
          <button type="button" class="solidBtn" @click="popupOff()">확인</button>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Api from '../../api/index';
export default {
  data() {
    return {
      contactName : null, // 사용자 입력 이름
      contactPhone : null, // 사용자 입력 연락처
      contactEmail : null, // 사용자 입력 이메일
      contactCompany : null, // 사용자 입력 기업명
      contactContent : null, // 사용자 입력 상담내용
      agreeCheck : false, // 개인정보 수집 동의 여부
      phoneCheck : false, // 연락처 양식 통과 여부
      currentStat : '', //
      formData : { // 보낼 form
        name : '',
        phone : '',
        email : '',
        company : '',
        content : ''
      },
      defaultMsg : '',
      popup : {
        classActive : '',
        type : '',
        msg : ''
      },
      swiperOption: {
        loop: true,
        pagination: {
          el : ".dotPager",
          bulletClass: 'default',
          bulletActiveClass: 'active',
          renderBullet: function (index, className) {
            return '<button type="button" class="' + className + '">' + '</button>';
          }
        },
        navigation : {
          nextEl : ".contactAddrWrap__btn .right",
          prevEl : ".contactAddrWrap__btn .left"
        }
      }
    }
  },
  methods: {
    checkForm() {
      if (this.agreeCheck == false) {
        this.popup = {classActive: "active", type: "fail", msg: "개인정보 수집 및 이용에 동의해 주셔야 합니다."};
        this.currentStat = 'check';
      } else if(this.contactName == null || this.contactName == '') {
        this.popup = {classActive: "active", type: "fail", msg: "성명을 입력해주세요."};
        this.currentStat = 'name'
      } else if (this.contactPhone == null || this.contactPhone == '') {
        this.popup = {classActive: "active", type: "fail", msg: "연락처를 입력해주세요."};
        this.currentStat = 'phone'
      } else if (this.contactEmail == null || this.contactEmail == '') {
        this.popup = {classActive: "active", type: "fail", msg: "이메일 주소를 입력해주세요."};
        this.currentStat = 'mail'
      } else if (this.contactContent == null || this.contactContent == '') {
        this.popup = {classActive: "active", type: "fail", msg: "상담 신청 내용을 입력해주세요."}
        this.currentStat = 'detail'
      } else {
        this.checkPhoneNumber();
        if(this.phoneCheck === true) {
          this.checkEmail();
        }
      }
    },
    checkPhoneNumber() {
      var phoneNum = this.contactPhone;
      var patternPhone = /^(01[016789]|02|0[3-9][0-9]{1,2})-?[0-9]{3,4}-?[0-9]{1,4}$/;
      if (!patternPhone.test(phoneNum)) {
        this.popup = {classActive: "active", type: "fail", msg: "연락처 형식이 맞지 않습니다."};
        this.phoneCheck = false;
        this.currentStat = 'phone';
        return;
      } else {
        this.phoneCheck = true
      }
    },
    checkEmail() {
      var emailAddr = this.contactEmail;
      // eslint-disable-next-line
      var regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if(emailAddr.length < 6 || !regExpEmail.test(emailAddr)) {
          this.popup = {classActive: "active", type: "fail", msg: "이메일 주소 형식이 맞지않습니다."};
          this.currentStat = 'mail'
          return;
      } else {
        if(this.phoneCheck === true) {
          this.sendCotactApi();
        }
      }
    },
    sendCotactApi() {
      let formData = new FormData();
      formData.append("name", this.contactName);
      formData.append("phone", this.contactPhone);
      formData.append("email", this.contactEmail);
      formData.append("company", this.contactCompany);
      formData.append("content", this.contactContent);
      Api.sendContact(formData).then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.popup = {classActive: "active", type: "success", msg: ""};
              this.contactName = '';
              this.contactPhone = '';
              this.contactEmail = '';
              this.contactCompany = '';
              this.contactContent = '';
              this.agreeCheck = false;
            } else {
              this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg};
            }
          } else {
            this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg}
          }
        }).catch((err) => {
          console.log(err);
          this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg}
        });
    },
    popupOff() {
      const agreeSection = document.querySelector('.contactInfo');
      const nameBox = document.querySelector('#txt1_1');
      const phoneBox = document.querySelector('#txt2_1');
      const mailBox = document.querySelector('#txt3_1');
      const detailBox = document.querySelector('#txt5_1');
      this.popup.classActive = '';
      switch(this.currentStat) {
        case 'check' : // if(currentStat === 'check')
          agreeSection.scrollIntoView({  block: 'start', behavior: 'smooth' });
        break;
        case 'name' : // if(currentStat === 'name')
          nameBox.focus({behavior: 'smooth'});
        break;
        case 'phone' : // if(currentStat === 'phone')
          phoneBox.focus({behavior: 'smooth'});
        break;
        case 'mail' : // if(currentStat === 'mail')
          mailBox.focus({behavior: 'smooth'});
        break;
        case 'detail' : // if(currentStat === 'detail')
          detailBox.focus({behavior: 'smooth'});
        break;
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>