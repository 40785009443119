<template>
  <div>
    <aboutHead :subHeadNum="1"/>
    <section class="subMain">
      <h3 class="bottomLineTit">회사소개</h3>
      <p>
        주식회사 에이치엠씨네트웍스는 빅데이터를 활용하여 의료산업에서 정보의 비대칭을 해소하며
        서비스 혁신을 이끌어갑니다.<br />
        간병비 예측 알고리즘, 간병인 가상 모바일 통장 등 누구도 시도하지 않은 서비스를 제공하며,<br />
        간병을 넘어 헬스케어와 관련된 모든 문제를 원스톱으로 해결할 수 있는 “헬스케어 플랫폼”을 향해
        나아가고 있습니다.
      </p>
      <div class="subMain__img hmc clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000" />
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000" />
      </div>
      <div class="subMain__ideol">
        <h3 class="bottomLineTit left">회사이념</h3>
        <div class="subMain__slide">
          <button type="button" class="subMain__slide--btn prev">left</button>
          <button type="button" class="subMain__slide--btn next">right</button>
          <swiper class="subMainSlideWrap clear" :options="swiperOption">
            <swiper-slide class="subMainSlideWrap__list respect">
              <h4>존중</h4>
              <p>아픈 환자들과 그들을 케어하는 의료기관의 <br />입장을 중시하여 판단한다.</p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list value">
              <h4>가치</h4>
              <p>
                환자들과 의료기관의 잠재된 요구까지 <br />찾아내어 환자들과 의료기관의 <br />기대를 뛰어넘는
                서비스를 제공한다.
              </p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list innov">
              <h4>혁신</h4>
              <p>0.1의 차이가 아닌 <br />1의 차이를 만들어 낸다.</p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list creative">
              <h4>창의 / 자율</h4>
              <p>
                고정관념에서 벗어나 새로운 생각과 시도를 추구하며, <br />자기 책임과 권한에 따라
                주인의식을 가지고 일한다.
              </p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list commu">
              <h4>소통</h4>
              <p>
                직급에 상관없이 상호 존칭을 통해 <br />수평적인 커뮤니케이션을 하면서 <br />책임은 부서장이 공은
                팀원이 치하받는다.
              </p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list develop">
              <h4>자기계발</h4>
              <p>
                각 분야에서 최고의 퍼포먼스를 내기 위한 <br />노력을 게을리하지 않으며, <br />회사는 필요한
                지원을 아끼지 않는다.
              </p>
            </swiper-slide>
            <swiper-slide class="subMainSlideWrap__list result">
              <h4>성과주의</h4>
              <p>
                도전적인 목표를 세우고 지속적인 성과창출에 노력하며, <br />지위 고하를 막론하고 능력과
                장단기 성과에 따라 <br />공정하게 평가하고 보상한다.
              </p>
            </swiper-slide>
          </swiper>
          <article class="dotPager clear"></article>
        </div>
      </div>
      <article class="subMain__ban invest">
        <div class="basicWrap clear">
          <div class="subMain__ban--left">
            <h3 class="bottomLineTit left">주요 투자사</h3>
            <p>
              주식회사 에이치엠씨네트웍스는 누적 투자 255억원으로 사업의 가치를 인정 받았습니다.
              의료업계 전반에서 탄탄히 성장하고 있으며 간병 시장의 선두주자로 자리잡고자 합니다.
            </p>
          </div>
          <ul class="subMain__ban--investImg clear">
            <li v-for="investItem in investList" :key="investItem.num">
              <img :src="investItem.img" :alt="investItem.name">
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import aboutHead from './aboutHead.vue';
import clientList from '@/assets/js/clientList';
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      investList : clientList.invest,
      swiperOption: {
        slidesPerView: "3",
        slidesPerGroup: 3,
        loop: true,
        spaceBetween: 0,
        autoplay: {
          delay: 2000
        },
        navigation: {
          nextEl: ".subMain__slide--btn.next",
          prevEl: ".subMain__slide--btn.prev",
        },
        pagination: {
          el: ".dotPager",
          clickable: true,
          bulletClass: "default",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return '<button type="button" class="' + className + '">' + "</button>";
          },
        },
        breakpoints: {
          600: {
            slidesPerView: "1",
            slidesPerGroup: 1,
          }
        }
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    aboutHead
  },
};
</script>

<style scope>
.swiper-notification {
  display: none;
}
</style>
