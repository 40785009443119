<template>
  <footer>
    <div class="footerWrap clear">
      <div class="footerWrap__info clear">
        <div class="footerLeft">
          <img src="@/assets/img/ic_hmclogo_02.png" alt="HMC NETWORKS" />
          <div class="footerLeft__corpInfo">
            <p>제휴문의</p>
            <small>TEL: 1811-5949 │ FAX: 070-7966-5949</small>
            <small>COPYRIGHT HMC NETWORKS Co., LTD. ALL RIGHTS RESERVED</small>
          </div>
          <div class="footerLeft__ask">
            <p>간병인 매칭 문의</p>
            <a href="https://www.carenation.kr/customer" target="_blank"
              >간병인 문의 하러가기</a
            >
          </div>
        </div>
        <div class="famSite">
          <button
            type="button"
            @click="famOpen = !famOpen"
            v-bind:class="{ open: famOpen }"
          >
            Family site
          </button>
          <ul class="famSite__list">
            <li><a href="http://www.xn--2z1bo3hu1kgtaocx4j22s77a.com/shop/main/index.php" target="_blank">HMC MEDICAL</a></li>
            <li><a href="https://www.carenation.kr/" target="_blank">케어네이션</a></li>
            <li><a href="http://datalab.carenation.kr/" target="_blank">DATA LAB.</a></li>
          </ul>
        </div>
      </div>
      <ul class="footerWrap__invest">
        <li>
          <img src="@/assets/img/img_footBan_01.png" alt="KVCA 한국벤처캐피탈협회" />
        </li>
        <li>
          <img src="@/assets/img/img_footBan_02.png" alt="INNOBIZ 사단법인 중소기업기술혁신협회" />
        </li>
        <li>
          <img src="@/assets/img/img_footBan_03.png" alt="중소벤처기업부" />
        </li>
        <li>
          <img src="@/assets/img/img_footBan_04.png" alt="MAINBiz" />
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      famOpen: false,
    };
  },
  watch: {
    '$route' : function() {
      this.famOpen = false
    }
  },
};
</script>

<style></style>
