export default {
  head: [
    {
      num: "01",
      title: "간병 서비스",
    },
    {
      num: "02",
      title: "동행 서비스",
    },
    {
      num: "03",
      title: "가사돌봄 서비스",
    },
    {
      num: "04",
      title: "방문요양 서비스",
    },
    {
      num: "05",
      title: "비대면 결제",
    },
    {
      num: "06",
      title: "의료용품 스토어",
    },
    {
      num: "07",
      title: "병원·약국 찾기",
    },
    {
      num: "08",
      title: "복약 관리",
    },
    {
      num: "09",
      title: "요양원 찾기",
    },
    {
      num: "10",
      title: "상조·장례",
    },
  ],
  body: [
    {
      img: "labImg01",
      title: "간병 서비스",
      desc: "간병인 프로필/후기/간병비 확인 후,\n카드로 간편 결제하세요",
      sub: "4년 연속 소비자만족도 1위 간병인 찾기\n오늘도 더 좋은 간병 문화를 만들어갑니다",
    },
    {
      img: "labImg02",
      title: "동행 서비스",
      desc: "집부터 병원까지,\n든든한 동행인을 찾아보세요",
      sub: "부모님 동행, 마음까지 함께 해 줄 사람\n케어네이션에 있습니다",
    },
    {
      img: "labImg03",
      title: "가사돌봄 서비스",
      desc: "가사부터 돌봄까지,\n필요한 만큼 편리하게 이용해 보세요",
      sub: "맞춤형 가사돌봄을 통해\n케어메이트가 따뜻한 일상을 함께합니다",
    },
    {
      img: "labImg04",
      title: "방문요양 서비스",
      desc: "방문요양, 주야간보호, 방문간호, 방문목욕,\n단기보호, 복지용구 시설들을 편하게 찾아보세요",
      sub: "기관평가와 함께\n집근처 센터까지 확인할 수 있어요",
    },
    {
      img: "labImg05",
      title: "간병비·병원비 비대면 결제",
      desc: "병원에 갈 필요 없이 간병비 따로 병원비 따로!\n이제 앱으로 편하게 결제하세요",
      sub: "*병원비 결제 서비스는 추후 업데이트 될 예정입니다",
    },
    {
      img: "labImg06",
      title: "의료용품 스토어",
      desc: "매번 구매해야 하는 의료용품,\n합리적인 가격에 간편하게 구매하세요",
      sub: "원하는 제품을 손쉽게\n검색할 수 있습니다",
    },
    {
      img: "labImg07",
      title: "병원·약국 찾기",
      desc: "근처 병원·약국의\n영업시간, 진료과목을 확인하세요",
      sub: "케어네이션 맵으로 병원 찾기가\n더욱 편리해졌습니다",
    },
    {
      img: "labImg08",
      title: "복약 정보 검색·알림 관리",
      desc: "잊기 쉬운 약 복용 방법\n대신 챙겨드릴게요",
      sub: "처방받은 약, 매일 챙겨 먹어야 하는 영양제\n모두 깜빡하지 않도록 복약 알림을 설정하세요",
    },
    {
      img: "labImg09",
      title: "요양원 찾기",
      desc: "우리 부모님 더욱 편안하게 모실 수 있도록\n원하는 등급의 시설을 찾아보세요",
      sub: "요양원, 노인공동생활 가정 등을\n빠르게 검색할 수 있어요",
    },
    {
      img: "labImg10",
      title: "부고장 관리·상조·장례",
      desc: "장례 시설 찾기, 부고장 작성 및 관리,\n상조가입 등이 가능해요",
      sub: "갑작스럽게 준비해야 하는 장례 여정을\n케어네이션이 함께 합니다",
    },
  ],
};
