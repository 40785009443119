<template>
  <div>
    <businessHead :subHeadNum="5"/>
    <section class="subMain offCare">
      <h3 class="bottomLineTit">그린실버(오프라인 간병)</h3>
      <p>
        오프라인 간병은 다년간의 업계 경력 노하우로 60여 곳의 1,2,3차 의료기관에 <br />
        간병 서비스를 안정적으로 공급하는 국내 유일 기업입니다.
      </p>
      <div class="subMain__img off clear">
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
        <div data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></div>
      </div>

      <div class="subMain__ban clear">
        <swiper class="subMainBanWrap" :options="swiperOption">
          <swiper-slide class="silver01">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC 오프라인 간병의 강점</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>01</span>일일 공급량 국내 최대 규모</h4>
                <p>간병 서비스 공급량이 400명 ~ 600명으로 국내 최대 규모를 자랑합니다.</p>
              </div>
              <!-- // 슬라이드 1 -->
            </div>
          </swiper-slide>
          <swiper-slide class="silver02">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC 오프라인 간병의 강점</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>02</span>간병인 공급병원 60개사 이상</h4>
                <p>
                  오프라인 간병은 60여 곳의 1,2,3차 의료기관에 간병인을 공급하는<br />
                  유일한 회사입니다.
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="silver03">
            <div class="basicWrap clear">
              <div class="subMain__ban--left">
                <h3 class="bottomLineTit left">HMC 오프라인 간병의 강점</h3>
              </div>
              <div class="subMain__ban--rgtInfo">
                <h4><span>03</span>간병인배상책임보험</h4>
                <p>다년간의 업력을 인정받아 단체보험 가입을 완료했습니다.</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 공통 요소 -->
        <div class="fnbBtnWrap clear">
          <button type="button" class="prev">이전</button>
          <button type="button" class="next">다음</button>
        </div>
        <div class="dotPager"></div>
        <!-- //공통 요소 -->
      </div>

      <article class="sub__customer clear">
        <div class="basicWrap">
          <h3 class="bottomLineTit left">주요 고객사</h3>
          <div class="sub__customer--img">
            <ul class="clear">
              <li v-for="offItem in offList" :key="offItem.num">
                <img :src="offItem.img" :alt="offItem.name">
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import businessHead from './businessHead.vue';
import clientList from '@/assets/js/clientList';
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      offList: clientList.off,
      swiperOption: {
        slidesPerView: "1",
        loop: true,
        autoplay: {
          delay: 2000,
        },
        navigation: {
          nextEl: ".fnbBtnWrap .next",
          prevEl: ".fnbBtnWrap .prev",
        },
        pagination: {
          el: ".dotPager",
          clickable: true,
          bulletClass: "default",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return '<button type="button" class="' + className + '">' + "</button>";
          },
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    businessHead
  },
};
</script>

<style></style>
