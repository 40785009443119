<template>
  <article>
      <div class="subBan biz">
        <h2>BUSINESS</h2>
      </div>
      <div class="subTabMenu">
        <ul class="clear">
          <li>
            <router-link to="/business/lab" :class="{'active' : subHeadNum == 4}">HMC LAB.</router-link>
          </li>
          <li>
            <router-link to="/business/offline" :class="{'active' : subHeadNum == 5}">그린실버(오프라인 간병)</router-link>
          </li>
          <li>
            <router-link to="/business/fnb" :class="{'active' : subHeadNum == 6}">HMC F&B</router-link>
          </li>
          <li>
            <router-link to="/business/medical" :class="{'active' : subHeadNum == 7}">HMC MEDICAL</router-link>
          </li>
          <li>
            <router-link to="/business/payment" :class="{'active' : subHeadNum == 8}">HMC PAYMENT</router-link>
          </li>
          <li>
            <router-link to="/business/subSidiaries" :class="{'active' : subHeadNum == 9}">SUBSIDIARIES</router-link>
          </li>
        </ul>
      </div>
    </article>
</template>

<script>
export default {
  props : {
    subHeadNum : Number
  },
  mounted() {
    sessionStorage.setItem("subPick", this.subHeadNum);
  }
}
</script>