<template>
  <div>
    <aboutHead :subHeadNum="2"/>
    <section class="subMain">
      <h3 class="bottomLineTit">연혁</h3>
      <p>
        주식회사 에이치엠씨네트웍스는 의료업계 전반에서 탄탄한 기업으로 성장했습니다.<br />
        간병 시장의 선두주자로 자리잡고자 노력하는 주식회사 에이치엠씨네트웍스를 만나보세요.
      </p>
      <div class="subHistoryBan">
        <p>Social Care Data Concierge</p>
      </div>
      <div class="subHistory">
        <ul class="subHistory__list clear">
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2013</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his01"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>주식회사 에이치엠씨네트웍스 설립</strong>
              <p>법인 설립</p>
              <p>HMC 메디몰 오픈</p>
              <p>HMC 메디컬 오픈</p>
              <p>요양포털닷컴 오픈</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2014</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his02"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>간병 서비스의 가능성</strong>
              <p>현대그린푸드 식자재 납품 계약</p>
              <p>병원천사 MOU 체결</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2016</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his03"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>그린실버 간병인 협회(오프라인간병) 인수</strong>
              <p>간병인 협회 인수</p>
              <p>식자재 업체 인수</p>
              <p>한화푸디스트 식자재 납품 계약</p>
              <p>벤처기업 인증</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2019</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his04"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>HMC F&B, MEDICAL 사업부 확장</strong>
              <span>SEED</span>
              <p>로우파트너스 투자 유치</p>
              <span>Pre-A</span>
              <p>HB인베스트먼트㈜ 투자 유치</p>
              <p>신용보증기금 투자 유치</p>
              <p class="devide">정부과제 창업도약 패키지 선정</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2020</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his05"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>케어네이션 서비스 런칭 및 투자 유치</strong>
              <span>Series-A</span>
              <p>HB인베스트먼트㈜ 투자 유치</p>
              <p>㈜LSK 인베스트먼트 투자 유치</p>
              <p>㈜하나벤처스 투자 유치</p>
              <p class="devide left">경영혁신형 중소기업 (Main-Biz) 인증</p>
              <p>기술혁신형 중소기업 (Inno-Biz) 인증</p>
              <p>근로시간 단축 확인</p>
              <p>미래성과공유기업 확인</p>
              <p>스마트 서비스 지원 사업 선정</p>
              <p>IP(지식재산)나래 프로그램 지원사업 선정</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2021</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his06"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>DATA LAB 구축 및 투자 유치</strong>
              <span>Pre- Series B</span>
              <p>HB인베스트먼트㈜ 투자 유치</p>
              <p>㈜LSK 인베스트먼트 투자 유치</p>
              <p>㈜하나벤처스 투자 유치</p>
              <p>삼성벤처투자㈜ 전략적 투자 유치 (삼성화재)</p>
              <p>신한금융투자㈜ 투자 유치</p>
              <p>새한창업투자㈜ 투자 유치</p>
              <p>삼성벤처투자㈜ 투자 유치</p>
              <p><b>누적 투자금액 235억원 달성</b></p>
              <p class="devide">삼성화재해상보험㈜ MOU 체결</p>
              <p>보람상조 간병 서비스 활성화를 위한 MOU 체결</p>
              <p>CARENATION DATA LAB 구축 <small>(간병동향리포트 발간)</small></p>
              <p>중소벤처기업부 스마트서비스 지원사업 우수 혁신기업 선정</p>
              <p>고용노동부 강소기업 선정</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2022</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his07"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>케어네이션 Ver 2.0 출시</strong>
              <span>Pre- Series B</span>
              <p>데일리 파트너스 투자유치</p>
              <p><b>누적 투자금액 255억원 달성</b></p>
              <p class="devide left">㈜씨엔컴퍼니 인수</p>
              <p>어르신 세상 인수</p>
              <p>케어네이션 Ver.2.0 출시</p>
              <p>간병인 매칭 기업회원용 서비스 출시</p>
              <p>삼성화재 협업 모바일 간병인배상책임보험 출시</p>
              <p>삼성화재 동행서비스 특화 배상책임보험 도입</p>
            </div>
          </li>
          <li class="clear">
            <h4 data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">2023</h4>
            <i data-aos="fade-up" data-aos-once="false" data-aos-duration="3000"></i>
            <div class="subHistory__list--img" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <figure class="his08"></figure>
            </div>
            <div class="subHistory__list--txt" data-aos="fade-up" data-aos-once="false" data-aos-duration="3000">
              <strong>동행 · 가사돌봄 서비스 출시</strong>
              <p>㈜어르신세상 인수</p>
              <p>동행 서비스 출시</p>
              <p>가사돌봄 서비스 출시</p>
              <p>㈜그로우플러스 인수</p>
              <p>삼성화재 애니핏 간병서비스 연동 출시</p>
            </div>
            <i></i>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import aboutHead from './aboutHead.vue';
export default {
    components: {
    aboutHead
  },
}
</script>